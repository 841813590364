import { call, put, takeEvery } from "redux-saga/effects";

import * as vegAction from "./veg.action";

import Swal from "sweetalert2";
import { vegActionTypes } from "./veg.model";
import { deleteVegApi, getVegApi, postVegtApi } from "./veg.api";

function* handlepostProductData(action) {
  try {
    
    const response = yield call(postVegtApi, action.payload);
    yield put(vegAction.postVegSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "Success",
      text: "Product List Added Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(vegAction.postVegFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handlegetProductData(action) {
  try {
    const response = yield call(getVegApi, action.payload);
    yield put(vegAction.getVegSuccess(response));
  } catch (e) {
    yield put(vegAction.getVegFailure(e));
  }
}

function* handleDeleteVeg(action) {
  
  try {
    const response = yield call(deleteVegApi, action.payload);
    yield put(vegAction.deleteVegSuccess(response));
   
    Swal.fire({
      title: "Success",
      text: "Vegetable List Delete Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(vegAction.deleteVegFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}
export function* VegSaga() {
  yield takeEvery(vegActionTypes.POSTVEG_REQUEST, handlepostProductData);
  yield takeEvery(vegActionTypes.GETVEG_REQUEST, handlegetProductData);
  yield takeEvery(vegActionTypes.DELETEPVEG_REQUEST, handleDeleteVeg);
}

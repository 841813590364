import { call,put,takeEvery } from "redux-saga/effects";
import * as marketAddAction  from "./market.action";
import Swal from "sweetalert2";


import { marketAddActionType } from "./market.model";
import { deleteMarketApi, GetMarketApi, GetMarketPriceApi, PostMarketApi, PostMarketPriceApi, updateMarketApi } from "./market.api";

function* handleSubmit(action){
    try{
        console.log(action, "5trgybb");
        const response= yield  call(PostMarketApi,action.payload);
        yield put(marketAddAction.postMarketSuccess(response))
       
        Swal.fire({
          title: "Success",
          text: "Market Added Succesfully!",
          icon: "success",
        });
    }
    catch(e){
        yield put(marketAddAction.postMarketFailure(e));
        Swal.fire({
            title: "Error",
            text: e?.response?.data?.message,
            icon: "error",
          });
    }
}
function* getCompany(action){

  
    try{
        const response= yield  call(GetMarketApi,action.payload);
        yield put(marketAddAction.getMarketSuccess(response))
        console.log(response, "5trgybb");
       
    }
    catch(e){
        yield put(marketAddAction.getMarketFailure(e));
       
    }
}
function* handleUpdateData(action) {
  
    try {
      const response = yield call(updateMarketApi, action.payload);
      yield put(marketAddAction.updateMarketSuccess(response));
    
      Swal.fire({
        title: "Success",
        text: "Market Updated Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(marketAddAction.updateMarketFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  function* handleDeleteData(action) {
  
    try {
      const response = yield call(deleteMarketApi, action.payload);
      yield put(marketAddAction.deleteMarketSuccess(response));
     
      Swal.fire({
        title: "Success",
        text: "Market Delete Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(marketAddAction.deleteMarketFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  function* handleSubmitPrice(action){
    try{
        console.log(action, "5trgybb");
        const response= yield  call(PostMarketPriceApi,action.payload);
        yield put(marketAddAction.postMarketPriceSuccess(response))
       
        Swal.fire({
          title: "Success",
          text: "Market Price Added Succesfully!",
          icon: "success",
        });
    }
    catch(e){
        yield put(marketAddAction.postMarketPriceFailure(e));
        Swal.fire({
            title: "Error",
            text: e?.response?.data?.message,
            icon: "error",
          });
    }
}
function* getMarketPrice(action){

  
  try{
      const response= yield  call(GetMarketPriceApi,action.payload);
      yield put(marketAddAction.getMarketPriceSuccess(response))
      console.log(response, "5trgybb");
     
  }
  catch(e){
      yield put(marketAddAction.getMarketPriceFailure(e));
     
  }
}
export function* MarketSaga(){
    yield takeEvery(marketAddActionType.POSTMARKET_REQUEST,handleSubmit);
    yield takeEvery(marketAddActionType.GETMARKET_REQUEST,getCompany);
    yield takeEvery(marketAddActionType.UPDATEMARKET_REQUEST,handleUpdateData);
    yield takeEvery(marketAddActionType.DELETEMARKET_REQUEST,handleDeleteData);
    yield takeEvery(marketAddActionType.POSTMARKETPRICE_REQUEST,handleSubmitPrice);
    yield takeEvery(marketAddActionType.GETMARKETPRICE_REQUEST,getMarketPrice);
}
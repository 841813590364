import { decryptdata } from "../../utils/encrypt&decrypt";
import { companyAddActionType, companyInitalState } from "./company.model";

const reducer = (state = companyInitalState, action) => {
  switch (action.type) {
    case companyAddActionType.POSTCOMPANY_REQUEST:
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
      };
    case companyAddActionType.POSTCOMPANY_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
      };
    case companyAddActionType.POSTCOMPANY_FAILURE:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
      };
    case companyAddActionType.GETCOMPANY_REQUEST:
      return {
        ...state,
        getLoading: true,
      };
    case companyAddActionType.GETCOMPANY_SUCCESS:
      const { payload } = action;
      console.log(action.payload, process.env.REACT_APP_SECRETKEY, "jkmls");
      let payloaddatas = JSON.parse(
        decryptdata(
          action.payload?.data?.datas,
          process.env.REACT_APP_SECRETKEY
        )
      );
      console.log(payloaddatas, "yhoio");
      return {
        ...state,
        getLoading: false,
        allCompany: payloaddatas,
      };
    case companyAddActionType.GETCOMPANY_FAILURE:
      return {
        ...state,
        getLoading: false,
      };
    case companyAddActionType.UPDATECOMPANY_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case companyAddActionType.UPDATECOMPANY_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
      };
    case companyAddActionType.UPDATECOMPANY_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
      };

    case companyAddActionType.DELETECOMPANY_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
      };
    case companyAddActionType.DELETECOMPANY_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
      };
    case companyAddActionType.DELETECOMPANY_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
      };
      case companyAddActionType.GETCOMPANYUSER_REQUEST:
        console.log("yes");
        
      return {
        ...state,
        getUserLoading: true,
      };
    case companyAddActionType.GETCOMPANYUSER_SUCCESS:
      // const { payload } = action;
      console.log(action.payload, process.env.REACT_APP_SECRETKEY, "jkmls");
      let payloaddatas1 = JSON.parse(
        decryptdata(
          action.payload?.data?.datas,
          process.env.REACT_APP_SECRETKEY
        )
      );
      console.log(payloaddatas1.data.id, "1d11");
      return {
        ...state,
       
        getUserLoading:false,
        Companyuser:payloaddatas1,
        CompanyUser_id:payloaddatas1.data.id
       
      };
      
      
    case companyAddActionType.GETCOMPANYUSER_FAILURE:
      return {
        ...state,
        getUserLoading: false,
      };
    case companyAddActionType.RESETCOMPANY:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        getLoading: false,
        allCompany: [],
        updateLoading: false,
        updateSuccess: false,
        deleteLoading: false,
        deleteSuccess: false,
        getUserLoading:false,
        Companyuser:[],
      };
    default:
      return state;
  }
};

export { reducer as companyReducer };
export const CompanySelector = (state) => state.companyReducer;

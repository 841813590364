import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const PostCouponApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/coupon/", formData, Http.getAuthToken());
};

export const GetCouponApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "/coupon/", Http.getAuthToken());
  // return Http.get(
  //     baseUrl +
  //       "/coupon?query=" +
  //       query.query +
  //       "&prod_id=" +
  //       query.prod_id +
  //       "&page=" +
  //       query.page +
  //       "&limit=" +
  //       query.limit,
  //     {},Http.getAuthToken()
  //   );
};
export const updateCouponApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/coupon/update/" + formData?.id,
    formData,
    Http.getAuthToken()
  );
};

export const deleteCouponApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(
    baseUrl + "/coupon/delete/" + formData,
    Http.getAuthToken()
  );
};

export const vegActionTypes = {
    POSTVEG_REQUEST: "@@veg/POSTVEG_REQUEST",
    POSTVEG_SUCCESS: "@@veg/POSTVEG_SUCCESS",
    POSTVEG_FAILURE: "@@veg/POSTVEG_FAILURE",
  
    GETVEG_REQUEST: "@@veg/GETVEG_REQUEST",
    GETVEG_SUCCESS: "@@veg/GETVEG_SUCCESS",
    GETVEG_FAILURE: "@@veg/GETVEG_FAILURE",
  
    // UPDATEPRODUCT_REQUEST: "@@product/UPDATEPRODUCT_REQUEST",
    // UPDATEPRODUCT_SUCCESS: "@@product/UPDATEPRODUCT_SUCCESS",
    // UPDATEPRODUCT_FAILURE: "@@product/UPDATEPRODUCT_FAILURE",
  
    DELETEPVEG_REQUEST: "@@pveg/DELETEPVEG_REQUEST",
    DELETEPVEG_SUCCESS: "@@pveg/DELETEPVEG_SUCCESS",
    DELETEPVEG_FAILURE: "@@pveg/DELETEPVEG_FAILURE",
  
    // POSTPRODUCTPRICE_REQUEST: "@@product/POSTPRODUCTPRICE_REQUEST",
    // POSTPRODUCTPRICE_SUCCESS: "@@product/POSTPRODUCTPRICE_SUCCESS",
    // POSTPRODUCTPRICE_FAILURE: "@@product/POSTPRODUCTPRICE_FAILURE",
  
    // STATE_RESET_REQUEST:"@@product/STATE_RESET_REQUEST"
  };
  
  export const vegInitialState = {
    postVegLoading: false,
    VegSendSuccess: false,
    getVegLoading: false,
    getVegData: [],
    deleteVegLoading: false,
    deleteVegSuccess: false,
    
  };
  
  export const vegSelector = (state) => state.vegReducer;
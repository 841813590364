import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { useEffect, useState } from "react";

import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { CompanySelector } from "../../store/company/company.reducer";
import {
  deleteCompanyRequest,
  getCompanyRequest,
  resetCompany,
} from "../../store/company/company.action";
import { Col, Row } from "react-bootstrap";
function CompanyList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Selectors */
  const { allCompany,deleteSuccess } = useSelector(CompanySelector);

  /* State */

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  /* Function */
  const totalPages1 = Math.ceil(allCompany?.count / rowsPerPage) || 1;

  const handleDelete = (id) => {
    dispatch(deleteCompanyRequest(id));
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const currentPage = page; // Replace with your actual current page state
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  /* useEffect */

  // useEffect(()=>{
  //   if(deleteSuccess){
  //     dispatch(getCompanyRequest())
  //   }
  // },[deleteSuccess])

  useEffect(() => {
    const payload = {
      query: searchQuery,
      b2b_id: 0,
      page: currentPage,
      limit: rowsPerPage,
    };
    console.log(payload, "yes");

    dispatch(getCompanyRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage,deleteSuccess]);
  console.log(allCompany, "uiui");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Company List</h2>
            </div>
            <div>
              <button
                className="btn btn-primary btn-sm rounded"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(resetCompany());
                  navigate("/company/add");
                }}
              >
                {" "}
                Create new
              </button>
            </div>
          </div>
          {/* <div className="card mb-4">
            <div className="card-body">
              <article className="itemlist">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-sm-2 col-4 col-price  text-center  ">
                    <h5 className="mb-0">Company Name</h5>
                  </div>

                  <div className="col-lg-2 col-sm-4 col-4 col-name flex-grow-1 px-4  text-center">
                    <h5 className="mb-0 ">Contact Name</h5>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                    <h5 className="mb-0">Email</h5>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-status text-center">
                    <h5 className="mb-0">Contact Number</h5>
                  </div>
                  
                  <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                    <h5 className="mb-0">Status</h5>
                  </div>

                  <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                    <h5 className="mb-0">Action</h5>
                  </div>
                </div>
              </article>

              {allCompany.data && allCompany?.data?.length > 0 ? (
                allCompany?.data?.map((company, index) => (
                  <article className="itemlist" key={company?.id}>
                    <div className="row align-items-center">
                      
                      <div className="col-lg-2 col-sm-2 col-4 col-price">
                        <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{company?.companyName}</h6>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-price  ">
                        <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{company?.contactName}</h6>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                      <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{company?.email }</h6>
                          </div>
                        </a>
                        
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 text-center">
                      <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                          <h6 className="mb-0">{company?.contactNumber}</h6>
                          </div>
                        </a>
                        
                      </div>{" "}
                      
                      <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                      <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                          <span className="badge rounded-pill alert-success">
                              {company?.status == 1 ? "Active" : "Unactive"}
                            </span>
                         <h6 className="mb-0">{company?.city }</h6>   // no valid
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                        <button
                          className="btn btn-sm font-sm rounded btn-brand mr-5"
                          onClick={() =>
                            navigate(`/company/edit/${company?.id}`)
                          }
                        >
                          <i className="material-icons md-edit"></i> Edit
                        </button>
                        <button
                          className="btn btn-sm font-sm btn-light rounded"
                          onClick={() => handleDelete(company.id)}
                        >
                          <i className="material-icons md-delete_forever"></i>{" "}
                          Delete
                        </button>
                      </div>
                    </div>
                  </article>
                ))
              ) : (
                <p>No Company found</p>
              )}
            </div>
          </div> */}
          <div className="card mb-4">
            <header className="card-header">
              <Row className="gx-3">
                <Col lg={4} md={6} className="me-auto">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Col>
                <Col lg={2} md={3} xs={6}>
                  <select className="form-select">
                    <option>Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Show all</option>
                  </select>
                </Col>
                <Col lg={2} md={3} xs={6}>
                  <select
                    className="form-select"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option>Show 20</option>
                    <option>Show 30</option>
                    <option>Show 40</option>
                  </select>
                </Col>
              </Row>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th>Contact Name</th>
                      <th>Email</th>
                      <th>Contact Number</th>
                      <th>Status</th>
                      <th className="text-left"> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allCompany?.data?.map((ele, ind) => {
                      return (
                        <tr>
                          <td width="20%">
                            <a className="itemside" href="#">
                              <div className="info pl-3">
                                <h6 className="mb-0 title">
                                  {ele?.companyName}
                                </h6>
                              </div>
                            </a>
                          </td>
                          <td width="20%">
                            <a className="itemside" href="#">
                              <div className="info pl-3">
                                <h6 className="mb-0 title">
                                  {ele?.contactName}
                                </h6>
                              </div>
                            </a>
                          </td>
                          <td>{ele.email}</td>
                          <td width="20%">
                            <a className="itemside" href="#">
                              <div className="info pl-3">
                                <h6 className="mb-0 title">
                                  {ele?.contactNumber}
                                </h6>
                              </div>
                            </a>
                          </td>
                          <td>
                            <span className="badge rounded-pill alert-success">
                              {ele?.status == 1 ? "Active" : "Unactive"}
                            </span>
                          </td>
                          <td>
                            <button
                              className="btn btn-sm font-sm rounded btn-brand mr-5"
                              onClick={() =>
                                navigate(`/company/edit/${ele?.id}`)
                              }
                            >
                              <i className="material-icons md-edit"></i> Edit
                            </button>
                            <button
                              className="btn btn-sm font-sm btn-light rounded"
                              onClick={() => handleDelete(ele?.id)}
                            >
                              <i className="material-icons md-delete_forever"></i>{" "}
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="pagination-area mt-30 mb-50 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {pagesToDisplay.map((page) => {
                  return page != "..." ? (
                    <li
                      key={page}
                      className={`page-item b ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ) : page == "..." ? (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ) : (
                    ""
                  );
                })}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default CompanyList;

export const marketAddActionType={
    POSTMARKET_REQUEST:"@@market/POSTMARKET_REQUEST",
    POSTMARKET_SUCCESS:"@@market/POSTMARKET_SUCCESS",
    POSTMARKET_FAILURE:"@@market/POSTMARKET_FAILURE",

    GETMARKET_REQUEST:"@@market/GETMARKET_REQUEST",
    GETMARKET_SUCCESS:"@@market/GETMARKET_SUCCESS",
    GETMARKET_FAILURE:"@@market/GETMARKET_FAILURE",

    UPDATEMARKET_REQUEST:"@@market/UPDATEMARKET_REQUEST",
    UPDATEMARKET_SUCCESS:"@@market/UPDATEMARKET_SUCCESS",
    UPDATEMARKET_FAILURE:"@@market/UPDATEMARKET_FAILURE",

    DELETEMARKET_REQUEST:"@@market/DELETEMARKET_REQUEST",
    DELETEMARKET_SUCCESS:"@@market/DELETEMARKET_SUCCESS",
    DELETEMARKET_FAILURE:"@@market/DELETEMARKET_FAILURE",

    POSTMARKETPRICE_REQUEST:"@@market/POSTMARKETPRICE_REQUEST",
    POSTMARKETPRICE_SUCCESS:"@@market/POSTMARKETPRICE_SUCCESS",
    POSTMARKETPRICE_FAILURE:"@@market/POSTMARKETPRICE_FAILURE",

    GETMARKETPRICE_REQUEST:"@@market/GETMARKETPRICE_REQUEST",
    GETMARKETPRICE_SUCCESS:"@@market/GETMARKETPRICE_SUCCESS",
    GETMARKETPRICE_FAILURE:"@@market/GETMARKETPRICE_FAILURE",

    RESETMARKET:"@@/market/RESETMARKET"
}
export const marketInitalState={
    postMarketLoading:false,
    postMarketSuccess:false,
    getMarketLoading:false,
    getMarketSucess:false,
    allMarket:[],

    updateMarketLoading:false,
    updateMarketSuccess:false,
    deleteMarketLoading:false,
    deleteMarketSuccess:false,
    postMarketPriceLoading:false,
    postMarketPriceSuccess:false,
    getMarketPriceLoading:false,
    getMarketPriceSucess:false,
    allMarketPrice:[],
}
export const productActionTypes = {
  POSTPRODUCT_REQUEST: "@@product/POSTPRODUCT_REQUEST",
  POSTPRODUCT_SUCCESS: "@@product/POSTPRODUCT_SUCCESS",
  POSTPRODUCT_FAILURE: "@@product/POSTPRODUCT_FAILURE",

  GETPRODUCT_REQUEST: "@@product/GETPRODUCT_REQUEST",
  GETPRODUCT_SUCCESS: "@@product/GETPRODUCT_SUCCESS",
  GETPRODUCT_FAILURE: "@@product/GETPRODUCT_FAILURE",

  UPDATEPRODUCT_REQUEST: "@@product/UPDATEPRODUCT_REQUEST",
  UPDATEPRODUCT_SUCCESS: "@@product/UPDATEPRODUCT_SUCCESS",
  UPDATEPRODUCT_FAILURE: "@@product/UPDATEPRODUCT_FAILURE",

  DELETEPRODUCT_REQUEST: "@@product/DELETEPRODUCT_REQUEST",
  DELETEPRODUCT_SUCCESS: "@@product/DELETEPRODUCT_SUCCESS",
  DELETEPRODUCT_FAILURE: "@@product/DELETEPRODUCT_FAILURE",

  OUTOFSTOCKPRODUCT_REQUEST: "@@product/OUTOFSTOCKPRODUCT_REQUEST",
  OUTOFSTOCKPRODUCT_SUCCESS: "@@product/OUTOFSTOCKPRODUCT_SUCCESS",
  OUTOFSTOCKPRODUCT_FAILURE: "@@product/OUTOFSTOCKPRODUCT_FAILURE",

  POSTPRODUCTPRICE_REQUEST: "@@product/POSTPRODUCTPRICE_REQUEST",
  POSTPRODUCTPRICE_SUCCESS: "@@product/POSTPRODUCTPRICE_SUCCESS",
  POSTPRODUCTPRICE_FAILURE: "@@product/POSTPRODUCTPRICE_FAILURE",

  POSTPRODUCTSTOCK_REQUEST: "@@product/POSTPRODUCTSTOCK_REQUEST",
  POSTPRODUCTSTOCK_SUCCESS: "@@product/POSTPRODUCTSTOCK_SUCCESS",
  POSTPRODUCTSTOCK_FAILURE: "@@product/POSTPRODUCTSTOCK_FAILURE",

  GETPRODUCTSTOCK_REQUEST: "@@product/GETPRODUCTSTOCK_REQUEST",
  GETPRODUCTSTOCK_SUCCESS: "@@product/GETPRODUCTSTOCK_SUCCESS",
  GETPRODUCTSTOCK_FAILURE: "@@product/GETPRODUCTSTOCK_FAILURE",

  UPDATEPRODUCTSTOCK_REQUEST: "@@product/UPDATEPRODUCTSTOCK_REQUEST",
  UPDATEPRODUCTSTOCK_SUCCESS: "@@product/UPDATEPRODUCTSTOCK_SUCCESS",
  UPDATEPRODUCTSTOCK_FAILURE: "@@product/UPDATEPRODUCTSTOCK_FAILURE",

  POSTBULKPRODUCT_REQUEST: "@@product/POSTBULKPRODUCT_REQUEST",
  POSTBULKPRODUCT_SUCCESS: "@@product/POSTBULKPRODUCT_SUCCESS",
  POSTBULKPRODUCT_FAILURE: "@@product/POSTBULKPRODUCT_FAILURE",

  STATE_RESET_REQUEST:"@@product/STATE_RESET_REQUEST"
};

export const productInitialState = {
  postProLoading: false,
  productSendSuccess: false,
  getProLoading: false,
  updateProLoading: false,
  productUpdateSuccess: false,
  getProductData: [],
  deleteproductLoading: false,
  productDeleteSucces: false,
  outofstockLoading:false,
  outofstockSucess:false,
  postPriceLoading:false,
  postPriceSuccess:false,
  postStockLoading:false,
  postStockSuccess:false,
  getStockLoading:false,
  stock:[],
  updateStockLoading:false,
  updateStockSuccess:false,
  postBulkProLoading: false,
  productBulkSendSuccess: true,
};

export const productSelector = (state) => state.productReducer;

import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const getTransportApi = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +"/trasnportcost", Http.getAuthToken()
    );
  };

  export const postTranportApi = (formData) => {
  
    const baseUrl = getBaseEndpointUrl();
    return Http.post(
      baseUrl + "/trasnportcost",
      formData,
      Http.getAuthToken()
    );
  };

  export const updateTransportApi = ({formData}) => {
    console.log(formData,"ooo");
    
    const baseUrl = getBaseEndpointUrl();
    return Http.put(
      baseUrl + "/trasnportcost/" + formData?.id,
      formData,
      Http.getAuthToken()
    );
  };

  export const getVehicleApi = (query) => {
    const baseUrl = getBaseEndpointUrl();
    return Http.get(
      baseUrl +"/trasnportcost/vehicle", Http.getAuthToken()
    );
  };
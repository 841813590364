export const couponAddActionType={
    POSTCOUPON_REQUEST:"@@coupon/POSTCOUPON_REQUEST",
    POSTCOUPON_SUCCESS:"@@coupon/POSTCOUPON_SUCCESS",
    POSTCOUPON_FAILURE:"@@coupon/POSTCOUPON_FAILURE",

    GETCOUPON_REQUEST:"@@coupon/GETCOUPON_REQUEST",
    GETCOUPON_SUCCESS:"@@coupon/GETCOUPON_SUCCESS",
    GETCOUPON_FAILURE:"@@coupon/GETCOUPON_FAILURE",

    UPDATECOUPON_REQUEST:"@@coupon/UPDATECOUPON_REQUEST",
    UPDATECOUPON_SUCCESS:"@@coupon/UPDATECOUPON_SUCCESS",
    UPDATECOUPON_FAILURE:"@@coupon/UPDATECOUPON_FAILURE",

    DELETECOUPON_REQUEST:"@@coupon/DELETECOUPON_REQUEST",
    DELETECOUPON_SUCCESS:"@@coupon/DELETECOUPON_SUCCESS",
    DELETECOUPON_FAILURE:"@@coupon/DELETECOUPON_FAILURE",

    RESETCOUPON:"@@coupon/RESETCOUPON"
}
export const couponInitalState={
    postLoading:false,
    postSuccess:false,
    getLoading:false,
    allCoupons:[],
    updateLoading:false,
    updateSuccess:false,
    deleteLoading:false,
    deleteSuccess:false,
}
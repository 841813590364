import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import uploads from "../../assets/imgs/theme/upload.svg";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { productSelector } from "../../store/product/product.model";
import { useParams } from "react-router-dom";
import { NodeServer } from "../../services/config";
import { ProductAddListValiator } from "../../validators/Validators";
import {
  getProductRequest,
  postProductRequest,
  productStateResetRequest,
  UpdateProductRequest,
} from "../../store/product/product.action";
import { getCatRequest } from "../../store/category/category.action";
import { CatAddSelector } from "../../store/category/category.model";
import { getBrandRequest } from "../../store/brand/brand.action";
import { BrandSelector } from "../../store/brand/brand.model";
import { selectDefault, TaxPercentage } from "../../utils/Constants";
import { Link, useNavigate } from "react-router-dom";
// import Select from "react-dropdown-select";
 import Select from 'react-select'

function Pageformproduct() {
  const id = useParams();
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("id");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(ProductAddListValiator),
  });
  console.log(errors, "eireerei")
  /* Selectors */
  const { getCatData } = useSelector(CatAddSelector);
  // const { getBrandData } = useSelector(BrandSelector);
  const { productSendSuccess, productUpdateSuccess, getProductData } =
    useSelector(productSelector);
  console.log(getProductData, "sldfsf8sssn");
  /* State */
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [ids, setId] = useState(0);
  const options = [
    { value: '250', label: '250 GRAMS' },
    { value: '300', label: '300 GRAMS' },
    { value: '500', label: '500 GRAMS' },
    { value: '750', label: '750 GRAMS' },
    { value: '1000', label: '1 KG' },
  ]
  const [selectedOptions, setSelectedOptions] = useState([options[0],options[2]]);
  // console.log(sel,"se")
  const onSubmitHandler = (formData) => {
    // debugger
    delete formData.category_id;
    delete formData.tax;
    delete formData.c_rate;
    delete formData.msrp;
    delete formData.p_rate;
    delete formData.w_rate;
    formData.image = image;
    formData.user_id = user_id;
    formData.unit_type = selectedOptions.map(option => option.value)
    console.log(formData, "hjjak");
    if (ids === 0 || ids == undefined) {
      dispatch(postProductRequest(formData));
    } else {
      formData.id = ids;
      dispatch(UpdateProductRequest(formData));
    }
  };

  useEffect(() => {
    const payload = {
      query: "",
      page: "",
      limit: "",
    };
    dispatch(getCatRequest(payload));
    // dispatch(getBrandRequest(payload));
    dispatch(productStateResetRequest());
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (id && id?.id) {
      setId(id?.id);
    }
  }, [id]);

  useEffect(() => {
    if (ids !== 0) {
      const payload = {
        query: "",
        prod_id: ids,
        page: 0,
        limit: 0,
      };
      dispatch(getProductRequest(payload));
    }
  }, [ids]);

  useEffect(() => {
    if (getProductData?.count > 0) {
      getProductData?.data?.map((ele) => {
        setImagePreview(
          ele?.image?.includes("/products/")
            ? NodeServer.baseURL + ele?.image
            : NodeServer.baseURL + "/products" + ele?.image
        );

        reset({
          name: ele?.name,
          description: ele?.description,
          featured: ele?.featured,
          reg_price: ele?.reg_price,
          sale_price: ele?.sale_price,
          unit_type: ele?.unit_type,
        });


      const defaultSelectedOptions = ele?.unit_type.map(value => {
        return options.find(option => option.value === String(value)); 
      }).filter(Boolean); 
  
      setSelectedOptions(defaultSelectedOptions);
      });

    }
  }, [getProductData]);

  useEffect(() => {
    if (productSendSuccess || productUpdateSuccess) {
      navigate("/pageproductslist");
      dispatch(productStateResetRequest());
      reset();
      setId(0);
      setImagePreview(null);
      setImage(null);
      if (imageRef.current) {
        imageRef.current.value = "";
      }
    }
  }, [productSendSuccess, productUpdateSuccess]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Row>
              <Col md={12}>
                <div className="content-header ">
                  <h2 className="content-title">
                    {ids === 0 || ids == undefined
                      ? "Add New Product"
                      : "Edit Product"}
                  </h2>
                  <div>
                    <button
                      className="btn btn-md rounded font-sm hover-up"
                      type="submit"
                    >
                      Save Product
                    </button>
                  </div>
                </div>
              </Col>

              <Col lg={8} className="">
                <div className="card mb-4 overflow-visible">
                  <div className="card-header">
                    <h4>Product Information</h4>
                  </div>
                  <div className="card-body overfloe-visiable">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label" htmlFor="product_name">
                            Product Title
                          </label>
                          <input
                            className="form-control"
                            id="product_name"
                            type="text"
                            {...register("name")}
                            placeholder="Type here"
                          />
                          {errors?.name && (
                            <p className="text-danger">
                              {errors?.name?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Description</label>
                          <textarea
                            className="form-control"
                            {...register("description")}
                            placeholder="Type here"
                            rows="4"
                          ></textarea>
                          {errors?.description && (
                            <p className="text-danger">
                              {errors?.description?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row overflow-visiable">
                    
                      <div className="col-lg-6">
                        <div className="mb-4">
                          <label className="form-label">Featured</label>
                          <textarea
                            className="form-control"
                            {...register("featured")}
                            placeholder="Type here"
                            rows="4"
                          ></textarea>
                          {errors?.featured && (
                            <p className="text-danger">
                              {errors?.featured?.message}
                            </p>
                          )}
                        </div>
                      </div>

                     

                    </div>
                  </div>
                </div>
                <div className="card mb-4 overflow-visible">
                  <div className="card-header">
                    <h4>Price </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
             
                
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Regular Price</label>
                          <input
                            className="form-control"
                            {...register("reg_price")}
                            placeholder="Regular Price"
                            type="number"
                          />
                          {errors?.c_rate && (
                            <p className="text-danger">
                              {errors?.c_rate?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Sale Price </label>
                          <input
                            className="form-control"
                            {...register("sale_price")}
                            placeholder="Sale Price"
                            type="number"
                          />
                          {errors?.sale_price && (
                            <p className="text-danger">
                              {errors?.sale_price?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="mb-4">
                          <label className="form-label">Pack Weight </label>
                          <Select
                            isMulti
                            options={options}
                            className="form-selects overflow-visible"
                            classNamePrefix="select"
                            value={selectedOptions}
                            onChange={(selected) => setSelectedOptions(selected)}
                          />
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
              </Col>

              <Col lg={4}>
                <div className="card mb-4">
                  <div className="card-header">
                    <h4>Media</h4>
                  </div>
                  <div className="card-body">
                    <div className="input-upload">
                      {imagePreview && (
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                      )}
                      <input
                        className="form-control"
                        type="file"
                        ref={imageRef}
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="card mb-4 overflow-visible" >
                  <div className="card-header">
                    <h4>Category </h4>
                  </div>
                  <div className="card-body">
                    <div className="row gx-2">
                      <div className="col-sm-6 mb-3">
                        <label className="form-label">Category</label>
                         <select
                          className="form-select"
                          {...register("catgory_id")}
                        >
                          <option value={selectDefault} disabled>
                            SELECT
                          </option>
                          {getCatData?.data?.map((cat) => (
                            <option key={cat.id} value={cat.id}>
                              {cat?.name}
                            </option>
                          ))}
                        </select>
                        {errors?.catgory_id && (
                          <p className="text-danger">
                            {errors?.catgory_id?.message}
                          </p>
                        )} 
                             {/* <Select
                        placeholder="Category"
                        className="form-control"
                        options={getCatData?.data||[]}
                        disabled={false}
                        dropdownHandle={true}
                        searchable={true}
                        searchBy="name"
                        labelField={"name"}
                        valueField={"id"}
                        name="name"
                        values={selectedOption1||[]}
                        onChange={(selectedOption) =>{
                          setValue("catgory_id", selectedOption[0]?.id);
                          setSelectedOption1(selectedOption)
                        }
                        }
                      /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </section>

        <Footer />
      </main>
    </>
  );
}

export default Pageformproduct;

import { Col, Row } from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Link, useNavigate } from "react-router-dom";
import avatar1 from "../../assets/imgs/people/avatar1.jpg";
import avatar2 from "../../assets/imgs/people/avatar2.jpg";
import avatar3 from "../../assets/imgs/people/avatar3.jpg";
import avatar4 from "../../assets/imgs/people/avatar4.jpg";
import { deleteUserDataRequest, getUserDataRequest, logout, userSelector } from "../../store/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useState } from "react";
import { deleteVendorDataRequest, getVendorDataRequest, resetState } from "../../store/vendor/vendor.action";
import { vendorSelector } from "../../store/vendor/vendor.reducer";
function PageVendorslist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData, deleteLoadSuccess,createSuccess,updateSuccess } = useSelector(vendorSelector);
  console.log(userData, "userDAa");
  /* State */
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages = Math.ceil(userData?.count / rowsPerPage)||1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleDelete = (id) => {
    dispatch(deleteVendorDataRequest(id));
  };
  /* useEffect */
  useEffect(() => {
    const payload = {
      query: searchQuery,
      page: currentPage,
      limit: rowsPerPage,
      userId:""
    };
    dispatch(getVendorDataRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage]);
  useEffect(() => {
    if(deleteLoadSuccess||createSuccess||updateSuccess){
      const payload = {
        query: searchQuery,
        page: currentPage,
        limit: rowsPerPage,
        userId:""
      };
      dispatch(getVendorDataRequest(payload));
      dispatch(resetState())
    }
   
  }, [deleteLoadSuccess]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <h2 className="content-title">Vendors list</h2>
            <div><button className="btn btn-primary" href="#"
             onClick={(e) => {
              e.preventDefault();
              navigate("/vendor/add");
              dispatch(resetState())
            }}><i className="material-icons md-plus"></i> Create new</button></div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <Row className="gx-3">
                <Col lg={4} md={6} className="me-auto">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </Col>
                <Col lg={2} md={3} xs={6}>
                  <select className="form-select">
                    <option>Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Show all</option>
                  </select>
                </Col>
                <Col lg={2} md={3} xs={6}>
                  <select
                    className="form-select"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option>Show 20</option>
                    <option>Show 30</option>
                    <option>Show 40</option>
                  </select>
                </Col>
              </Row>
            </header>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Vendor</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Registered</th>
                      <th className="text-left"> Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.map((ele, ind) => {
                      return (
                        <tr>
                          <td width="20%">
                            <a className="itemside" href="#">
                              <div className="info pl-3">
                                <h6 className="mb-0 title">{ele?.name}</h6>
                                <small className="text-muted">
                                  Vendor ID: {ele?.id}
                                </small>
                              </div>
                            </a>
                          </td>
                          <td>{ele.email}</td>
                          <td>
                            <span className="badge rounded-pill alert-success">
                              {ele?.status == 1 ? "Active" : "Unactive"}
                            </span>
                          </td>
                          <td>{dayjs(ele?.created_at).format("DD/MM/YYYY")}</td>
                          <td>
                        
                        <button
                          className="btn btn-sm font-sm rounded btn-brand mr-5"
                          onClick={() =>
                            navigate(`/vendor/edit/${ele?.id}`)
                          }
                        >
                          <i className="material-icons md-edit"></i> Edit
                        </button>
                        <button
                          className="btn btn-sm font-sm btn-light rounded"
                          onClick={() => handleDelete(ele?.id)}
                        >
                          <i className="material-icons md-delete_forever"></i>{" "}
                          Delete
                        </button>
                      
                      </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-15 mb-50">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-start">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {[...Array(totalPages)]?.map((_, index) => (
                  <li
                    key={index + 1}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    <a className="page-link" href="#">
                      {index + 1}
                    </a>
                  </li>
                ))}

                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default PageVendorslist;

import { call,put,takeEvery } from "redux-saga/effects";
import * as companyAddAction  from "./company.action";
import Swal from "sweetalert2";
import { companyAddActionType } from "./company.model";
import { deleteCompanyApi, GetCompanyApi, GetCompanyUserApi, PostCompanyApi, updateCompanyApi } from "./company.api";

function* handleSubmit(action){
    try{
        console.log(action, "5trgybb");
        const response= yield call(PostCompanyApi,action.payload);
        yield put(companyAddAction.postCompanySuccess(response))
       
        Swal.fire({
          title: "Success",
          text: "Company Added Succesfully!",
          icon: "success",
        });
    }
    catch(e){
        yield put(companyAddAction.postCompanyFailure(e));
        Swal.fire({
            title: "Error",
            text: e?.response?.data?.message,
            icon: "error",
          });
    }
}
function* getCompany(action){

  
    try{
        const response= yield call(GetCompanyApi,action.payload);
        yield put(companyAddAction.getCompanySuccess(response))
        console.log(response, "5trgybb");
       
    }
    catch(e){
        yield put(companyAddAction.getCompanyFailure(e));
       
    }
}
function* handleUpdateCouponData(action) {
  
    try {
      const response = yield call(updateCompanyApi, action.payload);
      yield put(companyAddAction.updateCompanySuccess(response));
    
      Swal.fire({
        title: "Success",
        text: "Company Updated Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(companyAddAction.updateCompanyFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  function* handleDeleteCouponData(action) {
  
    try {
      const response = yield call(deleteCompanyApi, action.payload);
      yield put(companyAddAction.deleteCompanySuccess(response));
     
      Swal.fire({
        title: "Success",
        text: "Company Delete Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(companyAddAction.deleteCompanyFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  function* getCompanyUser(action){
    try{
      console.log("yes");
        const response= yield call(GetCompanyUserApi,action.payload);
        yield put(companyAddAction.getCompanyUserSuccess(response))
        console.log(response, "5trgybb");
       
    }
    catch(e){
        yield put(companyAddAction.getCompanyUserFailure(e));
       
    }
}
export function* CompanySaga(){
    yield takeEvery(companyAddActionType.POSTCOMPANY_REQUEST,handleSubmit);
    yield takeEvery(companyAddActionType.GETCOMPANY_REQUEST,getCompany);
    // yield takeEvery(companyAddActionType.GETCOMPANYUSER_REQUEST,getCompanyUser);
    yield takeEvery(companyAddActionType.UPDATECOMPANY_REQUEST,handleUpdateCouponData);
    yield takeEvery(companyAddActionType.DELETECOMPANY_REQUEST,handleDeleteCouponData);
    yield takeEvery(companyAddActionType.GETCOMPANYUSER_REQUEST,getCompanyUser);
}
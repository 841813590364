import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { useEffect } from "react";
import { useState } from "react";
import { deleteCouponRequest, getCouponRequest, resetCoupon } from "../../store/coupon/coupon.action";
import { CouponSelector } from "../../store/coupon/coupon.reducer";
function Coupons() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Selectors */
 
  const { allCoupons } = useSelector(CouponSelector);
  /* State */
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages1 = Math.ceil(allCoupons?.count / rowsPerPage) || 1;

  const handleDelete = (id) => {
    dispatch(deleteCouponRequest(id));
    dispatch(getCouponRequest());

  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const currentPage = page; // Replace with your actual current page state
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  /* useEffect */


  useEffect(() => {
    const payload = {
      query: searchQuery,
      prod_id: 0,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getCouponRequest());
  }, [searchQuery, currentPage, rowsPerPage,]);
  console.log(allCoupons, "uiui");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Coupons List</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              {/* <a className="btn btn-light rounded font-md" href="#">
                Export
              </a>
              <a className="btn btn-light rounded font-md" href="#">
                Import
              </a> */}
              <button
                className="btn btn-primary btn-sm rounded"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(resetCoupon());
                  navigate("/coupons/add");
                }}
              >
                {" "}
                Create new
              </button>
            </div>
          </div>
          <div className="card mb-4">
            <div className="card-body">
              <article className="itemlist">
                <div className="row align-items-center">
                  <div className="col-lg-2 col-sm-2 col-4 col-price  text-center  ">
                    <h6 className="mb-0">Coupon Name</h6>
                  </div>

                  <div className="col-lg-2 col-sm-4 col-4 col-name flex-grow-1 px-4  text-center">
                    <h6 className="mb-0 ">Coupon Code</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-status text-center">
                    <h6 className="mb-0">Date</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                    <h6 className="mb-0">Type</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                    <h6 className="mb-0">value</h6>
                  </div>

                  {/* <div className="col-lg-1 col-sm-2 col-4 col-date">
                    <h6 className="mb-0">Created Date</h6>
                  </div> */}
                  <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                    <h6 className="mb-0">Action</h6>
                  </div>
                </div>
              </article>

              {allCoupons && allCoupons?.length > 0 ? (
                allCoupons?.map((coupon, index) => (
                  <article className="itemlist" key={coupon?.id}>
                    <div className="row align-items-center">
                      {/* <div className="col col-check flex-grow-0">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" />
                        </div>
       
       </div> */}
                      <div className="col-lg-2 col-sm-2 col-4 col-price">
                        <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{coupon?.coupon_name}</h6>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-price  ">
                        <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{coupon?.coupon_code}</h6>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 text-center">
                      <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{dayjs(coupon.date_from).format("DD/MM/YYYY")} -
                            {dayjs(coupon.date_to).format("DD/MM/YYYY")}</h6>
                          </div>
                        </a>
                        
                      </div>{" "}
                      <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                      <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{coupon?.percentage === null?"Flat Price":"Percentage"}</h6>
                          </div>
                        </a>
                        
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                      <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{coupon?.percentage === null?"₹"+coupon?.price_flat:coupon?.percentage+"%"}</h6>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                        <button
                          className="btn btn-sm font-sm rounded btn-brand mr-5"
                          onClick={() =>
                            navigate(`/coupons/edit/${coupon?.id}`)
                          }
                        >
                          <i className="material-icons md-edit"></i> Edit
                        </button>
                        <button
                          className="btn btn-sm font-sm btn-light rounded"
                          onClick={() => handleDelete(coupon.id)}
                        >
                          <i className="material-icons md-delete_forever"></i>{" "}
                          Delete
                        </button>
                      </div>
                    </div>
                  </article>
                ))
              ) : (
                <p>No products found</p>
              )}
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {pagesToDisplay.map((page) => {
                  return page != "..." ? (
                    <li
                      key={page}
                      className={`page-item b ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ) : page == "..." ? (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ) : (
                    ""
                  );
                })}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Coupons;

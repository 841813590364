import { decryptdata } from "../../utils/encrypt&decrypt";
import { vegActionTypes, vegInitialState } from "./veg.model";

const reducer = (state = vegInitialState, action) => {
  switch (action.type) {
    case vegActionTypes.POSTVEG_REQUEST:
      return {
        ...state,
        postVegLoading: true,
        VegSendSuccess: false,
      };
    case vegActionTypes.POSTVEG_SUCCESS:
      return {
        ...state,
        postVegLoading: false,
        VegSendSuccess: true,
      };
    case vegActionTypes.POSTVEG_FAILURE:
      return {
        ...state,
        postVegLoading: false,
        VegSendSuccess: false,
      };
    case vegActionTypes.GETVEG_REQUEST:
      return {
        ...state,
        getVegLoading: true,
      };
    case vegActionTypes.GETVEG_SUCCESS:
      console.log(action?.payload, process.env.REACT_APP_SECRETKEY, "jkmls");
      let payloaddatas11 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          process.env.REACT_APP_SECRETKEY
        )
      );
      console.log(payloaddatas11, "yhoio");
      return {
        ...state,
        getVegLoading: false,
        getVegData: payloaddatas11,
        // getVegData: action?.payload?.data,
      };
    case vegActionTypes.GETVEG_FAILURE:
      return {
        ...state,
        getVegLoading: false,
      };
    case vegActionTypes.DELETEPVEG_REQUEST:
      return {
        ...state,
        deleteVegLoading: true,
        deleteVegSuccess: false,
      };
    case vegActionTypes.DELETEPVEG_SUCCESS:
      return {
        ...state,
        deleteVegLoading: false,
        deleteVegSuccess: true,
      };
    case vegActionTypes.DELETEPVEG_FAILURE:
      return {
        ...state,
        deleteVegLoading: false,
        deleteVegSuccess: false,
      };
    default:
      return state;
  }
};
export { reducer as vegReducer };

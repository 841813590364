import React, { useEffect, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { ManageBannerValidator } from '../../utils/Validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { DatePicker } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { AdminSettingReducer, SettingSelector } from '../../store/settings/settings.reducer';
import { getbannerDataRequest, postBannerManageRequest } from '../../store/settings/settings.action';
import dayjs from 'dayjs';

function Banner() {
    const [startDate, setStartDate] = useState();
    const [startDate1, setStartDate1] = useState();
    const [img, setImg] = useState("");
    const [errorMsg, seterrorMsg] = useState({
        doj: false,
        ele: false,
        img: false,
      });
    const {bannerData,bannerDataSucess}=useSelector(SettingSelector);
    // console.log(bannerData,'ll')
    const dispatch=useDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm({
        resolver: yupResolver(ManageBannerValidator),
        
      });
      
  const onSubmitHandler = (formData) => {
    // dispatch(postSettingDataRequest(formData));
    console.log(formData, "4567yuhjkm");
    console.log(startDate, "4567yuhjkm");
    console.log(startDate1, "4567yuhjkm");
    console.log(img, "4567yuhjkm");
    formData.image=img
    formData.date_from = dayjs(startDate).format("DD/MM/YYYY");
    formData.date_to = dayjs(startDate1).format("DD/MM/YYYY");
    console.log(formData, "4567yuhjkm");
    dispatch(postBannerManageRequest(formData))
  }
  useEffect(()=>{
    dispatch(getbannerDataRequest())
    // console.log(bannerData,'ll')
  },[])
  useEffect(()=>{
    console.log(bannerData,'ll')
    if(bannerData){
      reset({
        name:bannerData.name,
        content:bannerData.content,
        description:bannerData.description,
        quote:bannerData.quote,
    })
    }
  
    
  },[bannerData])
  return (
    <div>
         <Form 
          onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Row>
             

              <Col lg={12}>
                {/* <div className="card mb-4"> */}
                 
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="name">
                          Name
                        </label>
                        <input
                          className="form-control"
                          id="name"
                          type="text"
                          {...register("name")}
                          placeholder="Enter your name"
                        />
                        {errors?.name && (
                          <p className="text-danger">{errors?.name?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="quote">
                          Quote
                        </label>
                        <input
                          className="form-control"
                          id="quote"
                          type="text"
                          {...register("quote")}
                          placeholder="Enter your quote"
                        />
                        {errors?.quote && (
                          <p className="text-danger">
                            {errors?.quote?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="content">
                        Content
                        </label>
                       
                        <textarea
                          className="form-control"
                          id="content"
                          {...register("content")}
                          placeholder="Enter your content"
                          rows="3"
                        ></textarea>
                        {errors?.content && (
                          <p className="text-danger">
                            {errors?.content?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        
                        <label className="form-label" htmlFor="description">
                        Description 
                        </label>
                        
                        <textarea
                          className="form-control"
                          id="description"
                          {...register("description")}
                          placeholder="Enter your description"
                          rows="3"
                        ></textarea>
                        {errors?.description && (
                          <p className="text-danger">
                            {errors?.description?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    
                    <div className="row">
                    <div className="col-lg-6 mb-4">
                    <label className="form-label" htmlFor="delivery_text">
                         Date-From
                        </label><br />
                        <DatePicker
                        // className="w-100"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        />
                        {errorMsg?.doj && (
                          <p className="text-danger"> Date From is Required</p>
                        )}
                      </div>

                      <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="delivery_text">
                         Date To
                        </label><br />
                        <DatePicker
                            selected={startDate1}
                            onChange={(date) => setStartDate1(date)}
                        />
                        {errorMsg?.ele && (
                        <p className="text-danger"> Date To is Required</p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                    
                    
                      <div className="col-lg-6 mb-4 d-flex justify-content-end ">
                      <label className="form-label" htmlFor="img">
                       Image
                        </label>
                        <input type="file"   className="form-control w-100" onChange={(e)=>setImg(e.target.files[0])} required/>
                      </div>
                      <div className="col-lg-6 mb-4">
                       
                      </div>
                      
                    </div>
                    <div className="row">
                    
                    <div className="col-lg-6 mb-4">
                       
                      </div>
                      <div className="col-lg-6 mb-4 d-flex justify-content-end ">
                        <input type="submit"  value="submit" className="btn btn-md rounded font-sm hover-up" />
                      </div>
                      
                    </div>
                    </div>

                    
                  
                  
              </Col>
            </Row>
        
     
      </Form>
    </div>
  )
}

export default Banner
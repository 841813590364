import Swal from "sweetalert2";

export default class alertmessage {
  static sweetalert(message) {
    let title = "Message..!";
    let text = message;
    if (
      message === "UnAuthorized!" ||
      message === "Your session expired! Please login to continue!"
    ) {
      title = "Your Session Expired..!";
      text = "Please login to continue";
    }
    Swal.fire({
      title: title,
      text: text,
      type: "danger",
    }).then(function () {
      localStorage.clear();
      window.location.reload(true);
    });
  }
}

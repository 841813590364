import { decryptdata } from "../../utils/encrypt&decrypt";

import { marketAddActionType, marketInitalState } from "./market.model";

const reducer = (state = marketInitalState, action) => {
  switch (action.type) {
    case marketAddActionType.POSTMARKET_REQUEST:
      return {
        ...state,
        postMarketLoading: true,
        postMarketSuccess: false,
      };
    case marketAddActionType.POSTMARKET_SUCCESS:
      return {
        ...state,
        postMarketLoading: false,
        postMarketSuccess: true,
      };
    case marketAddActionType.POSTMARKET_FAILURE:
      return {
        ...state,
        postMarketLoading: false,
        postMarketSuccess: false,
      };
    case marketAddActionType.GETMARKET_REQUEST:
      return {
        ...state,
        getMarketLoading: true,
      };
    case marketAddActionType.GETMARKET_SUCCESS:
      const { payload } = action;
      console.log(action.payload, process.env.REACT_APP_SECRETKEY, "jkmls");
      let payloaddatas = JSON.parse(
        decryptdata(
          action.payload?.data?.datas,
          process.env.REACT_APP_SECRETKEY
        )
      );
      console.log(payloaddatas, "yhoio");
      return {
        ...state,
        getMarketLoading: false,
        allMarket: payloaddatas,
        getMarketSucess:true
      };
    case marketAddActionType.GETMARKET_FAILURE:
      return {
        ...state,
        getMarketLoading: false,
      };
    case marketAddActionType.UPDATEMARKET_REQUEST:
      return {
        ...state,
        updateMarketLoading: true,
        updateMarketSuccess: false,
      };
    case marketAddActionType.UPDATEMARKET_SUCCESS:
      return {
        ...state,
        updateMarketLoading: false,
        updateMarketSuccess: true,
      };
    case marketAddActionType.UPDATEMARKET_FAILURE:
      return {
        ...state,
        updateMarketLoading: false,
        updateMarketSuccess: false,
      };

    case marketAddActionType.DELETEMARKET_REQUEST:
      return {
        ...state,
        deleteMarketLoading: true,
        deleteMarketSuccess: false,
      };
    case marketAddActionType.DELETEMARKET_SUCCESS:
      return {
        ...state,
        deleteMarketLoading: false,
        deleteMarketSuccess: true,
      };
    case marketAddActionType.DELETEMARKET_FAILURE:
      return {
        ...state,
        deleteMarketLoading: false,
        deleteMarketSuccess: false,
      };
      case marketAddActionType.POSTMARKETPRICE_REQUEST:
        return {
          ...state,
          postMarketPriceLoading: true,
          postMarketPriceSuccess: false,
        };
      case marketAddActionType.POSTMARKETPRICE_SUCCESS:
        return {
          ...state,
          postMarketPriceLoading: false,
          postMarketPriceSuccess: true,
        };
      case marketAddActionType.POSTMARKETPRICE_FAILURE:
        return {
          ...state,
          postMarketPriceLoading: false,
          postMarketPriceSuccess: false,
        };
        case marketAddActionType.GETMARKETPRICE_REQUEST:
      return {
        ...state,
        getMarketPriceLoading: true,
      };
    case marketAddActionType.GETMARKETPRICE_SUCCESS:
      // const { payload } = action;
      console.log(action.payload, process.env.REACT_APP_SECRETKEY, "jkmls");
      let payloaddatas1 = JSON.parse(
        decryptdata(
          action.payload?.data?.datas,
          process.env.REACT_APP_SECRETKEY
        )
      );
      console.log(payloaddatas1, "yhoio");
      return {
        ...state,
        getMarketPriceLoading: false,
        allMarketPrice: payloaddatas1,
        getMarketPriceSucess:true
      };
    case marketAddActionType.GETMARKETPRICE_FAILURE:
      return {
        ...state,
        getMarketPriceLoading: false,
      };
    case marketAddActionType.RESETMARKET:
      return {
        ...state,
        postMarketLoading: false,
        postMarketSuccess: false,
        getMarketLoading: false,
        allMarket: [],
        updateMarketLoading: false,
        updateMarketSuccess: false,
        deleteMarketLoading: false,
        deleteMarketSuccess: false,
        postMarketPriceLoading: false,
        postMarketPriceSuccess: false,
        getMarketPriceLoading:false,
        getMarketPriceSucess:false,
        allMarketPrice:[],
      };
    default:
      return state;
  }
};

export { reducer as MarketReducer };
export const MarketSelector = (state) => state.MarketReducer;

import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import dayjs from "dayjs";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import avatar4 from "../../assets/imgs/people/avatar4.jpg";
import avatar3 from "../../assets/imgs/people/avatar3.jpg";
import avatar2 from "../../assets/imgs/people/avatar2.jpg";
import {
  dueDayDataRequest,
  getCardDataRequest,
} from "../../store/dashboard/dashboard.action";
import { dashboardSelector } from "../../store/dashboard/dashboard.model";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { productSelector } from "../../store/product/product.model";
import { PriceChangeValidator } from "../../validators/Validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  getProductRequest,
  postBulkProductRequest,
  postProductRequestPrice,
  productStateResetRequest,
  UpdateProductRequest,
} from "../../store/product/product.action";
import { Modal, Button } from "react-bootstrap";
// import { Button, Modal } from "rsuite";
import SampleExcel from "../../assets/excel/SampleExcel.xlsx";
import { read, utils, writeFile } from "xlsx";

function Home() {
  const dispatch = useDispatch();

  let { getProductData, postPriceSuccess } = useSelector(productSelector);
  // console.log(getProductData.data[0].reg_price,"aaa");
  const [Productdata, setProductdata] = useState();

  console.log(getProductData, "datssdda");
  console.log(Productdata, "vasgdfs");
  const onProductSelect = (id) => {
    console.log(
      getProductData?.map((cat) => cat),
      "bbb"
    );
    const sel = getProductData?.find((cat) => cat.id === Number(id));
    console.log(sel.sale_price, "oooo");
    // setS_price(sel.sale_price)
    // setReg_price(sel.reg_price)
  };
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { cardData, dueDayData } = useSelector(dashboardSelector);
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [importErr, setImportErr] = useState({ err: false, msg: "" });
  const [array, setArray] = useState([]);
  const [uploadbtn, setuploadbtn] = useState(true);
  const [importSuccess, setImportSuccess] = useState({
    success: false,
    msg: "",
  });

  const [m_price, setM_price] = useState(100);
  const profitRefs = useRef([]);
  //function
  const onSubmitPrice = () => {
    console.log(Productdata, "ppp");
    let UpdateProducts = Productdata?.map((products) =>
      _.omit(products, [
        "category",
        "updated_by",
        "image",
        "created_by",
        "status",
        "trash",
        "updated_at",
        "created_at",
        "catgory_id",
        "description",
        "featured",
        "unit_type",
        "user_id",
        "name",
      ])
    );
    dispatch(postProductRequestPrice(UpdateProducts));
  };
  const onSalechange = (e, index) => {
    const updatedProductData = [...Productdata];
    updatedProductData[index] = {
      ...updatedProductData[index],
      sale_price: e.target.value,
    };
    setProductdata(updatedProductData);
  };
  const onRegchange = (e, index) => {
    const updatedProductData = [...Productdata];
    updatedProductData[index] = {
      ...updatedProductData[index],
      reg_price: e.target.value,
    };
    setProductdata(updatedProductData);
  };
  const handleShow = () => {
    setShow(true);
    console.log(132423);
    console.log(show, "dcuyd");
  };
  const handleClose = () => {
    setShow(false);
    setErrors({});
    setImportErr({ err: false, msg: "" });
  };
  const handleUplad = () => {
    let payload = {
      datas: array,
    };
    dispatch(postBulkProductRequest(payload));
    setShow(false);
    // dispatch(getProductRequest());
  };

  const isFound = (list, check) => {
    console.log(list, check, "lisdfdfst");
    console.log(check);
    let checked = list.filter((element) => {
      if (element.name === check) {
        console.log(element.id, "uyfyu");
        return element.id;
      }
      return false;
    });
    console.log(checked, "checked");
    return checked;
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const fileBuffer = await file.arrayBuffer();
    const workbook = read(fileBuffer, {
      type: "string",
      raw: true,
      dateNF: "dd/MM/yyyy",
    });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = utils.sheet_to_json(worksheet, { raw: false });

    if (data.length === 0) {
      setErrors({ file: ["Uploaded file is empty"] });
      setImportErr({ err: true, msg: "The uploaded file is empty" });
      event.target.value = null;
      setuploadbtn(true);
      return;
    }
    setuploadbtn(false);
    setErrors({});
    setImportErr({ err: false, msg: "" });

    const newErrors = {};

    const validateField = (item, field, row, errorKey, errorMsg) => {
      if (!item[field]) {
        newErrors[errorKey] = newErrors[errorKey] || [];
        newErrors[errorKey].push(`${errorMsg} in row ${row}`);
        return false;
      }
      return true;
    };

    const arrayObj = data
      .map((item, i) => {
        console.log(item["Product Name"], "cuygau");
        const row_no = i + 2;

        if (
          !validateField(
            item,
            "Product Name",
            row_no,
            "productName",
            "Product Name is missing"
          )
        ) {
          return null;
        }
        if (
          !validateField(
            item,
            "Retail Rate",
            row_no,
            "retailRate",
            "Retail Rate is missing"
          )
        ) {
          return null;
        }
        if (
          !validateField(
            item,
            "Sale Rate",
            row_no,
            "saleRate",
            "Sale Rate is missing"
          )
        ) {
          return null;
        }
        const Productidcheck = isFound(
          getProductData?.data,
          item["Product Name"]
        );
        console.log(Productidcheck, "dafcuyg");
        if (Productidcheck.length === 0) {
          newErrors.ProductName = newErrors.ProductName || [];
          newErrors.ProductName.push(`Invalid Product Name in row ${row_no}`);
          return null;
        }

        const Productid = Productidcheck[0].id;

        const transformedItem = {
          product_name: Productid,
          retail_rate: item["Retail Rate"],
          sale_rate: item["Sale Rate"],
        };
        return transformedItem;
      })
      .filter((item) => item !== null);

    if (Object.keys(newErrors).length > 0) {
      event.target.value = null;
      setErrors(newErrors);
      setImportErr({ err: true, msg: "Errors found in the file" });
    } else {
      setImportSuccess({ success: true, msg: "Validation success!" });
      setArray(arrayObj);
    }

    console.log(arrayObj, "Transformed array of objects");
  };

  const onChangeProfit = (e, index, product) => {
    const profit = e.target.value;
    const updatedProductData = [...Productdata];
    let mprice = product.market_price ?? 0;
    const updatedProduct = {
      ...updatedProductData[index],
      // profit,
      reg_price: parseFloat(mprice) + ((profit * 2) / 100) * mprice,
      sale_price: parseFloat(mprice) + (profit / 100) * mprice,
      market_price: mprice,
    };
    updatedProductData[index] = updatedProduct;
    setProductdata(updatedProductData);
  };
  /* useEffect */
  useEffect(() => {
    setProductdata(getProductData?.data);
  }, [getProductData]);
  useEffect(() => {
    dispatch(getCardDataRequest());
    // dispatch(dueDayDataRequest({}));
    const payload = {
      query: "",
      prod_id: 0,
      page: 0,
      limit: "",
    };
    dispatch(getProductRequest(payload));
    dispatch(productStateResetRequest());
  }, []);
  useEffect(() => {
    if (postPriceSuccess) {
      profitRefs.current.forEach((input) => {
        if (input) {
          input.value = "";
        }
      });
      const payload = {
        query: "",
        prod_id: 0,
        page: "",
        limit: "",
      };
      dispatch(getProductRequest(payload));
    }
  }, [postPriceSuccess]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main" style={{ minHeight: "600px" }}>
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Dashboard</h2>
              <p>Whole data about your business here</p>
            </div>

            {/* <div>
              <a className="btn btn-primary" href="#">
                <i className="text-muted material-icons md-post_add"></i>Create
                report
              </a>
            </div> */}
          </div>
          <Row>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Products</h6>
                    <span>{cardData?.total_products}</span>
                    <span className="text-sm">
                      in {cardData?.total_categories} categories
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Total</h6>
                    <span>{cardData?.total_orders}</span>
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                    {/* <span className="text-sm">
                      Shipping fees are not included
                    </span> */}
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    {/* <i className="text-success material-icons md-local_shipping"></i> */}
                    <i className="text-warning material-icons md-monetization_on"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Total</h6>
                    <span>
                      ₹{parseFloat(cardData?.total_revenue).toFixed(2)}
                    </span>
                    {/* <span className="text-sm">Excluding orders in transit</span> */}
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit %</h6>
                    <span>{cardData?.monthly_profit}</span>
                    <span className="text-sm">
                      Increase by 15% from Last year
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">New Products added</h6>
                    <span>{cardData?.new_products}</span>
                    <span className="text-sm">
                      in {cardData?.total_categories} categories
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Orders Received This Month
                    </h6>
                    <span>{cardData?.monthly_orders}</span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">
                      Revenue Received This Month
                    </h6>
                    <span>
                      ₹{parseFloat(cardData?.monthly_revenue).toFixed(2)}
                    </span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % this Month</h6>
                    <span>{cardData?.monthly_profit}</span>
                    <span className="text-sm">
                      Increase by 15% from Last month
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-primary-light">
                    <i className="text-primary material-icons md-shopping_bag"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Products out of stock</h6>
                    <span>{cardData?.out_of_stock_products}</span>
                    <span className="text-sm">
                      in {cardData?.total_categories} categories
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-success-light">
                    <i className="text-success material-icons md-local_shipping"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Orders Received Today</h6>
                    <span>{cardData?.today_orders}</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-warning-light">
                    <i className="text-warning material-icons md-monetization_on"></i>
                    {/* <i className="text-primary material-icons md-monetization_on"></i> */}
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Revenue Received Today</h6>
                    <span>
                      ₹{parseFloat(cardData?.today_revenue).toFixed(2)}
                    </span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit % Today</h6>
                    <span>{cardData?.today_profit}</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>
            {/* Price
            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Sale Price</h6>
                    <span>₹{s_price}</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>

            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Regular Price</h6>
                    <span>₹{reg_price}</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>

            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Market Price</h6>
                    <span>₹{m_price}</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col>

            <Col lg={3}>
              <div className="card card-body mb-4">
                <article className="icontext">
                  <span className="icon icon-sm rounded-circle bg-info-light">
                    <i className="text-info material-icons md-qr_code"></i>
                  </span>
                  <div className="text">
                    <h6 className="mb-1 card-title">Profit</h6>
                    <span>₹{profit}</span>
                    <span className="text-sm">
                      Increase by 15% from yesterday
                    </span>
                  </div>
                </article>
              </div>
            </Col> */}
          </Row>
        </section>

        <section style={{ minHeight: "100px" }}>
          <div className="container mb-5">
            {/* <div className="card">
              <div className="card-header">
                <h4>Profit Margin (%)</h4>
              </div>
              <div className="card-body">
                <article className="itemlist">
                  <div className="row align-items-center">
                    <div className="col-lg-4 col-sm-4 col-4 col-name flex-grow-1 px-4 ">
                      <h6 className="mb-0 ">Product Name</h6>
                    </div>
                    <div className="col-lg-2 col-sm-2 col-4 col-price">
                      <h6 className="mb-0">Market Price</h6>
                    </div>
                    <div className="col-lg-2 col-sm-2 col-4 col-date">
                      <h6 className="mb-0">Profit Percentage</h6>
                    </div>

                    <div className="col-lg-2 col-sm-2 col-4 col-price">
                      <h6 className="mb-0">Sale Price</h6>
                    </div>
                    <div className="col-lg-2 col-sm-2 col-4 col-price">
                      <h6 className="mb-0">Regular Price</h6>
                    </div>
                  </div>
                </article>
                <div className="card-body">
                  {Productdata && Productdata?.length > 0 ? (
                    Productdata?.map((product, index) => (
                      <article className="itemlist" key={product.id}>
                        <div className="row align-items-center">
                          <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                            <a className="itemside" href="#">
                              <div className="info">
                                <h6 className="mb-0">{product.name}</h6>
                                <p className="text-muted">
                                  {product.category.name}
                                </p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4 col-price">
                            <span>₹{m_price}</span>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4 col-price">
                            {/* <span>₹{product.reg_price}</span> */}
            {/* <input
                              type="text"
                              className="form-control"
                              ref={(el) => (profitRefs.current[index] = el)}
                              onChange={(e) => {
                                onChangeProfit(e, index);
                              }}
                            />
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4 col-price">
                            <input
                              type="text"
                              onChange={(e) => {
                                onSalechange(e, index);
                              }}
                              className="form-control"
                              value={product.sale_price}
                            />
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4 col-price">
                            <input
                              type="text"
                              className="form-control"
                              onChange={(e) => {
                                onRegchange(e, index);
                              }}
                              value={product.reg_price}
                            />
                          </div>
                        </div>
                      </article>
                    ))
                  ) : (
                    <p>No products found</p>
                  )}
                  <div className="row">
                    <div className="col-10"></div>
                    <div className="col-2">
                      <button
                        className="btn btn-md rounded font-sm hover-up  mt-3"
                        onClick={onSubmitPrice}
                      >
                        Save Price
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div
              className="accordion accordion-flush card mb-5"
              id="accordionFlushExample"
            >
              <div className="accordion-item p-0">
                <h4 className="accordion-header p-0" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {/* <div className="card-header m-0">
                          
                          </div> */}
                    Profit Margin (%)
                  </button>
                </h4>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    <div className="card-body">
                      <article className="itemlist">
                        <div className="row align-items-center">
                          <div className="col-lg-2 col-sm-4 col-4 col-name flex-grow-1 px-4 ">
                            <h6 className="mb-0 ">Product Name</h6>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4 col-price">
                            <h6 className="mb-0">Market Price</h6>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4 col-date">
                            <h6 className="mb-0">Profit Percentage</h6>
                          </div>

                          <div className="col-lg-2 col-sm-2 col-4 col-price">
                            <h6 className="mb-0">Sale Price</h6>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4 col-price">
                            <h6 className="mb-0">Regular Price</h6>
                          </div>
                          <div className="col-lg-2 col-sm-2 col-4 col-price">
                            <button
                              className="btn btn-primary btn-sm rounded me-3"
                              onClick={handleShow}
                            >
                              {" "}
                              Bulk Upload
                            </button>

                            <Modal show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>Bulk Update</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="d-flex flex-column ">
                                  <label className="mb-2">
                                    {" "}
                                    Download Sample file
                                  </label>

                                  <a
                                    href={SampleExcel}
                                    className="mb-2"
                                    download
                                  >
                                    <button className="btn btn-primary">
                                      Download Excel File
                                    </button>
                                  </a>
                                  <label className="mb-2"> Upload file</label>

                                  <input
                                    className="form-control mb-2"
                                    type="file"
                                    onChange={handleFileUpload}
                                    accept=".xlsx, .xls"
                                  />
                                  <p className="text-danger">
                                    {importErr && importErr.err == true
                                      ? importErr.msg
                                      : ""}
                                  </p>
                                  <p className="text-danger">
                                    {errors.ProductName}
                                  </p>
                                  <p className="text-danger">
                                    {errors.RetailRate}
                                  </p>
                                  <p className="text-danger">
                                    {errors.SaleRate}
                                  </p>
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  variant="primary"
                                  onClick={handleUplad}
                                  disabled={uploadbtn}
                                >
                                  Upload
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </article>
                      <div className="card-body">
                        {Productdata && Productdata?.length > 0 ? (
                          Productdata?.map((product, index) => (
                            <article className="itemlist" key={product.id}>
                              <div className="row align-items-center">
                                <div className="col-lg-4 col-sm-4 col-8 flex-grow-1 col-name">
                                  <a className="itemside" href="#">
                                    <div className="info">
                                      <h6 className="mb-0">{product.name}</h6>
                                      <p className="text-muted">
                                        {product.category.name}
                                      </p>
                                    </div>
                                  </a>
                                </div>
                                <div className="col-lg-2 col-sm-2 col-4 col-price">
                                  <span>₹{product.market_price ?? 0}</span>
                                </div>
                                <div className="col-lg-2 col-sm-2 col-4 col-price">
                                  {/* <span>₹{product.reg_price}</span> */}
                                  <input
                                    type="text"
                                    className="form-control"
                                    // ref={(el) => (profitRefs.current[index] = el)}
                                    onChange={(e) => {
                                      onChangeProfit(e, index, product);
                                    }}
                                  />
                                </div>
                                <div className="col-lg-2 col-sm-2 col-4 col-price">
                                  <input
                                    type="text"
                                    onChange={(e) => {
                                      onSalechange(e, index);
                                    }}
                                    className="form-control"
                                    value={product.sale_price}
                                  />
                                </div>
                                <div className="col-lg-2 col-sm-2 col-4 col-price">
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      onRegchange(e, index);
                                    }}
                                    value={product.reg_price}
                                  />
                                </div>
                              </div>
                            </article>
                          ))
                        ) : (
                          <p>No products found</p>
                        )}
                        <div className="row">
                          <div className="col-8"></div>
                          <div className="col-2"></div>
                          <div className="col-2">
                            <button
                              className="btn btn-md rounded font-sm hover-up  mt-3"
                              onClick={onSubmitPrice}
                            >
                              Save Price
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer className="mt-5" />
      </main>
    </>
  );
}
export default Home;

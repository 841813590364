import { call,put,takeEvery } from "redux-saga/effects";
import { couponAddActionType } from "./coupon.model";
import * as couponAddAction  from "./coupon.action";
import Swal from "sweetalert2";
import { deleteCouponApi, GetCouponApi, PostCouponApi, updateCouponApi } from "./coupon.api";

function* handleSubmit(action){
    try{
        console.log(action, "5trgybb");
        const response= yield  call(PostCouponApi,action.payload);
        yield put(couponAddAction.postCouponSuccess(response))
       
        Swal.fire({
          title: "Success",
          text: "Coupon Added Succesfully!",
          icon: "success",
        });
    }
    catch(e){
        yield put(couponAddAction.postCouponFailure(e));
        Swal.fire({
            title: "Error",
            text: e?.response?.data?.message,
            icon: "error",
          });
    }
}
function* getCoupons(action){
    console.log("YES");
    
    try{
        const response= yield  call(GetCouponApi);
        yield put(couponAddAction.getCouponSuccess(response))
        console.log(response, "5trgybb");
       
    }
    catch(e){
        yield put(couponAddAction.getCouponFailure(e));
       
    }
}
function* handleUpdateCouponData(action) {
  
    try {
      const response = yield call(updateCouponApi, action.payload);
      yield put(couponAddAction.updateCouponSuccess(response));
    
      Swal.fire({
        title: "Success",
        text: "Coupon Updated Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(couponAddAction.updateCouponFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  function* handleDeleteCouponData(action) {
  
    try {
      const response = yield call(deleteCouponApi, action.payload);
      yield put(couponAddAction.deleteCouponSuccess(response));
     
      Swal.fire({
        title: "Success",
        text: "Coupon Delete Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(couponAddAction.deleteCouponFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
export function* CouponSaga(){
    yield takeEvery(couponAddActionType.POSTCOUPON_REQUEST,handleSubmit);
    yield takeEvery(couponAddActionType.GETCOUPON_REQUEST,getCoupons);
    yield takeEvery(couponAddActionType.UPDATECOUPON_REQUEST,handleUpdateCouponData);
    yield takeEvery(couponAddActionType.DELETECOUPON_REQUEST,handleDeleteCouponData);
}
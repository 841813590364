import { action } from "typesafe-actions";
import { productActionTypes } from "./product.model";

export const postProductRequest = (req) =>
  action(productActionTypes.POSTPRODUCT_REQUEST, req);
export const postProductSuccess = (res) =>
  action(productActionTypes.POSTPRODUCT_SUCCESS, res);
export const postProductFailure = (err) =>
  action(productActionTypes.POSTPRODUCT_FAILURE, err);

export const getProductRequest = (req) =>
  action(productActionTypes.GETPRODUCT_REQUEST, req);
export const getProductSuccess = (res) =>
  action(productActionTypes.GETPRODUCT_SUCCESS, res);
export const getProductFailure = (err) =>
  action(productActionTypes.GETPRODUCT_FAILURE, err);

export const UpdateProductRequest = (req) =>
  action(productActionTypes.UPDATEPRODUCT_REQUEST, req);
export const UpdateProductSuccess = (res) =>
  action(productActionTypes.UPDATEPRODUCT_SUCCESS, res);
export const UpdateProductFailure = (err) =>
  action(productActionTypes.UPDATEPRODUCT_FAILURE, err);

export const deleteProductRequest = (req) =>
  action(productActionTypes.DELETEPRODUCT_REQUEST, req);
export const deleteProductSuccess = (res) =>
  action(productActionTypes.DELETEPRODUCT_SUCCESS, res);
export const deleteProductFailure = (err) =>
  action(productActionTypes.DELETEPRODUCT_FAILURE, err);

export const outofstockProductRequest = (req) =>
  action(productActionTypes.OUTOFSTOCKPRODUCT_REQUEST, req);
export const outofstockProductSuccess = (res) =>
  action(productActionTypes.OUTOFSTOCKPRODUCT_SUCCESS, res);
export const outofstockProductFailure = (err) =>
  action(productActionTypes.OUTOFSTOCKPRODUCT_FAILURE, err);

export const postProductRequestPrice = (req) =>
  action(productActionTypes.POSTPRODUCTPRICE_REQUEST, req);
export const postProductSuccessPrice = (res) =>
  action(productActionTypes.POSTPRODUCTPRICE_SUCCESS, res);
export const postProductFailurePrice = (err) =>
  action(productActionTypes.POSTPRODUCTPRICE_FAILURE, err);

export const postProductStockRequest = (req) =>
  action(productActionTypes.POSTPRODUCTSTOCK_REQUEST, req);
export const postProductStockSuccess = (res) =>
  action(productActionTypes.POSTPRODUCTSTOCK_SUCCESS, res);
export const postProductStockFailure = (err) =>
  action(productActionTypes.POSTPRODUCTSTOCK_FAILURE, err);

export const getProductStockRequest = (req) =>
  action(productActionTypes.GETPRODUCTSTOCK_REQUEST, req);
export const getProductStockSuccess = (res) =>
  action(productActionTypes.GETPRODUCTSTOCK_SUCCESS, res);
export const getProductStockFailure = (err) =>
  action(productActionTypes.GETPRODUCTSTOCK_FAILURE, err);

export const UpdateProductStockRequest = (req) =>
  action(productActionTypes.UPDATEPRODUCTSTOCK_REQUEST, req);
export const UpdateProductStockSuccess = (res) =>
  action(productActionTypes.UPDATEPRODUCTSTOCK_SUCCESS, res);
export const UpdateProductStockFailure = (err) =>
  action(productActionTypes.UPDATEPRODUCTSTOCK_FAILURE, err);

export const postBulkProductRequest = (req) =>
  action(productActionTypes.POSTBULKPRODUCT_REQUEST, req);
export const postBulkProductSuccess = (res) =>
  action(productActionTypes.POSTBULKPRODUCT_SUCCESS, res);
export const postBulkProductFailure = (err) =>
  action(productActionTypes.POSTBULKPRODUCT_FAILURE, err);

export const productStateResetRequest=(req)=>
    action(productActionTypes.STATE_RESET_REQUEST, req)
import { action } from "typesafe-actions";
import { vegActionTypes } from "./veg.model";


export const postVegRequest = (req) =>
  action(vegActionTypes.POSTVEG_REQUEST, req);
export const postVegSuccess = (res) =>
  action(vegActionTypes.POSTVEG_SUCCESS, res);
export const postVegFailure = (err) =>
  action(vegActionTypes.POSTVEG_FAILURE, err);

export const getVegRequest = (req) =>
  action(vegActionTypes.GETVEG_REQUEST, req);
export const getVegSuccess = (res) =>
  action(vegActionTypes.GETVEG_SUCCESS, res);
export const getVegFailure = (err) =>
  action(vegActionTypes.GETVEG_FAILURE, err);

export const deleteVegRequest = (req) =>
  action(vegActionTypes.DELETEPVEG_REQUEST, req);
export const deleteVegSuccess = (res) =>
  action(vegActionTypes.DELETEPVEG_SUCCESS, res);
export const deleteVegFailure = (err) =>
  action(vegActionTypes.DELETEPVEG_FAILURE, err);
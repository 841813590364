import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

import {
  getInventryDataRequest,
  postInventryDataRequest,
  updateInventryDataRequest,
} from "../../store/InventryScreen/Action";
import {
  getProductRequest,
  getProductStockRequest,
  postProductStockRequest,
  UpdateProductStockRequest,
} from "../../store/product/product.action";
import { selectDefault, transportStatus } from "../../utils/Constants";
import {
  ProductStockCreateValidator,
  ProductStockValidator,
  transportValidator,
} from "../../validators/Validators";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { InventrySelector } from "../../store/InventryScreen/Selector";
import { productSelector } from "../../store/product/product.model";
import Select from "react-select";
import {
  getTransportRequest,
  getVehicelRequest,
  postTransportRequest,
  updateTransportRequest,
} from "../../store/transport/transport.action";
import { transportSelector } from "../../store/transport/transport.reducer";

const Transport = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(transportValidator),
  });
  console.log(errors, "56789sj");
  const [value, setValues] = useState(null);
  const [comments, setComments] = useState();
  // Selectors
  const { getInventData, postSuccess, updateSuccess, deleteSuccess } =
    useSelector(InventrySelector);
  const { getProductData, stock, postStockSuccess, updateStockSuccess } =
    useSelector(productSelector);
  console.log(stock, "stockxx");
  const { vehicle, transport,postTranpostSuccess,updateTranpostSuccess } = useSelector(transportSelector);

  // States
  const [id, setId] = useState(0);
  const [edit, setEdit] = useState(false);
  const [vech_id, setVech_id] = useState();
  const [selid, setSelid] = useState();
  const [defaultid, setDefaultid] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages = Math.ceil(transport?.length / rowsPerPage) || 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const onSubmitHandlers = (formData) => {
    formData.vehicle_type_id = defaultid.value;
    console.log(formData, "lkjhg");
    if(id){
      formData.id=id
     dispatch(updateTransportRequest({formData})) 
    }
    else{
      dispatch(postTransportRequest(formData));
    }
   
  };

  const handleEdit = (val) => {
    
    reset({
        vehicle_number: val.vehicle_number,
        km_distance:val.km_distance,
        km_charge:val.km_charge,
        total_cost: val.total_cost,
        load_capacity:val.load_capacity
    })
    const sel=vech_id.find((ele)=> vehicle.vehicle_type_id== ele.vehicle_type_id)
    console.log(sel,'poppp');
    setDefaultid(sel)
    setId(val.id)
   
  };
  
  const idChange = (data) => {
    setDefaultid(data)
    setSelid(data.value);
  };

  //   const handleDelete = (id) => {
  //     dispatch(deleteInventryDataRequest(id));
  //   };
  useEffect(() => {
    dispatch(getVehicelRequest());
    // dispatch(getTransportRequest());
  }, []);
  console.log(vech_id, "vehicle");
  useEffect(() => {
    const opt = vehicle.map((ele) => {
      return { label: ele.name, value: ele.id };
    });
    setVech_id(opt);
  }, []);

  useEffect(() => {
    const payload = {
      query: searchQuery,

      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getTransportRequest(payload));
  }, [rowsPerPage, currentPage, searchQuery]);
  useEffect(() => {
    if(postTranpostSuccess ===true ||updateTranpostSuccess ===true){
      const payload = {
        query: 0,
  
        page: "",
        limit: "",
      };
      dispatch(getTransportRequest(payload));
    }
   
  }, [postTranpostSuccess,updateTranpostSuccess]);
  console.log(transport,"opop");

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Transport</h2>
              {/* <p>Add, edit or delete product Stock items</p> */}
            </div>
            <div>
              <input
                className="form-control bg-white"
                type="text"
                placeholder="Search Vechile"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={12}>
                  <form onSubmit={handleSubmit(onSubmitHandlers)}>
                    <div className="row">
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Vehicle Name </label>
                        <Select
                        value={defaultid}
                          className="basic-single "
                          classNamePrefix="select"
                          placeholder="Status...."
                          isDisabled={false}
                          isLoading={false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={true}
                          options={vech_id}
                          onChange={idChange}
                        />
                      </div>
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Vechile Number</label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("vehicle_number")}
                        />
                        {errors.product_id && (
                          <p className="text-danger">
                            {errors.vehicle_number.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row mb-5">
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">KiloMeter Distance</label>
                        <input
                          type="number"
                          className="form-control"
                          {...register("km_distance")}
                        />
                        {errors.total_qty && (
                          <p className="text-danger">
                            {errors.km_distance.message}
                          </p>
                        )}
                      </div>
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Kilometer Charges </label>

                        <input
                          type="number"
                          className="form-control"
                          {...register("km_charge")}
                        />
                        {errors.total_qty && (
                          <p className="text-danger">
                            {errors.km_charge.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Total Cost</label>
                        <input
                          type="number"
                          className="form-control"
                          {...register("total_cost")}
                        />
                        {errors.total_qty && (
                          <p className="text-danger">
                            {errors.total_cost.message}
                          </p>
                        )}
                      </div>
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Load Capacity </label>

                        <input
                          type="text"
                          className="form-control"
                          {...register("load_capacity")}
                        />
                        {errors.total_qty && (
                          <p className="text-danger">
                            {errors.load_capacity.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className=" row mb-5">
                      <div className="mb-4 col-lg-12 d-flex justify-content-end">
                        <button className="btn btn-primary" type="submit">
                          {id === 0 ? "Add stock" : "Update Stock"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
            {/* </div>

          <div className="card-body"> */}
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <th>ID</th> */}
                    <th>Vechile Number </th>
                    {/* <th>Opening Qty</th> */}
                    <th>KiloMeter Distance</th>
                    <th>Kilometer Cost</th>
                    {/* <th>Outward Qty</th> */}
                    <th>Total Cost</th>
                    <th>Load Capacity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {transport &&
                    transport?.map((item, index) => {
                      let date = new Date(item?.updated_at);
                      const formattedDate = date.toLocaleDateString("en-US");
                      return (
                        <tr key={item.id}>
                          <td>{item?.vehicle_number}</td>
                          <td>{item?.km_distance}</td>

                          <td>{item?.km_charge}</td>
                          <td>{item?.total_cost}</td>
                          <td>{item?.load_capacity}</td>

                          <td>
                            <button
                              className="btn btn-light"
                              onClick={() => handleEdit(item)}
                            >
                              Edit
                            </button>
                            {/* <button
                            className="btn btn-danger" 
                          >
                            Delete
                          </button> */}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            <div className="pagination-area mt-15 mb-50">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="material-icons md-chevron_left"></i>
                    </a>
                  </li>

                  {[...Array(totalPages)]?.map((_, index) => (
                    <li
                      key={index + 1}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      <a className="page-link" href="#">
                        {index + 1}
                      </a>
                    </li>
                  ))}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="material-icons md-chevron_right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default Transport;

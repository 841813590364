import { call, put, takeEvery } from "redux-saga/effects";
import {
  bulkCreateProductApi,
  deleteProductApi,
  getProductApi,
  getProductStockApi,
  outofstockApi,
  postProductApi,
  postProductPriceApi,
  postProductStockApi,
  updateProductApi,
  updateProductStockApi,
} from "./product.api";
import * as productAction from "./product.action";
import { productActionTypes } from "./product.model";
import Swal from "sweetalert2";


function* handlepostProductData(action) {
 
    try {
      const response = yield call(postProductApi, action.payload);
      yield put(productAction.postProductSuccess(response));
      console.log(response, "5trgybb");
      Swal.fire({
        title: "Success",
        text: "Product List Added Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(productAction.postProductFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  
  function* handlegetProductData(action) {
    try {
      const response = yield call(getProductApi, action.payload);
      yield put(productAction.getProductSuccess(response));
      
    } catch (e) {
      yield put(productAction.getProductFailure(e));
    }
  }
  
  function* handleUpdateProductData(action) {
  
    try {
      const response = yield call(updateProductApi, action.payload);
      yield put(productAction.UpdateProductSuccess(response));
    
      Swal.fire({
        title: "Success",
        text: "Product List Updated Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(productAction.UpdateProductFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  function* handleDeleteProductData(action) {
  
    try {
      const response = yield call(deleteProductApi, action.payload);
      yield put(productAction.deleteProductSuccess(response));
     
      Swal.fire({
        title: "Success",
        text: "Product List Delete Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(productAction.deleteProductFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  function* handlepostProductDataPrice(action) {
 
    try {
      const response = yield call(postProductPriceApi, action.payload);
      yield put(productAction.postProductSuccessPrice(response));
      console.log(response, "5trgybb");
      Swal.fire({
        title: "Success",
        text: "Product List Added Succesfully!",
        icon: "success",
      });
    } catch (e) {
      yield put(productAction.postProductFailurePrice(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
  }
  
function* handlepostProductStockData(action) {
 
  try {
    const response = yield call(postProductStockApi, action.payload);
    yield put(productAction.postProductStockSuccess(response));
    console.log(response, "5trgybb");
    Swal.fire({
      title: "Success",
      text: "Product List Added Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.postProductStockFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handlegetProductStockData(action) {
  try {
    const response = yield call(getProductStockApi, action.payload);
    yield put(productAction.getProductStockSuccess(response));
    
  } catch (e) {
    yield put(productAction.getProductStockFailure(e));
  }
}

function* handleUpdateProductStockData(action) {
  
  try {
    const response = yield call(updateProductStockApi, action.payload);
    yield put(productAction.UpdateProductStockSuccess(response));
  
    Swal.fire({
      title: "Success",
      text: "Product Stock Updated Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.UpdateProductStockFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}

function* handleOutofStock(action) {
  
  try {
    const response = yield call(outofstockApi, action.payload);
    yield put(productAction.outofstockProductSuccess(response));
    console.log(response?.data?.message,'uyfuyg');
    if(response?.data?.message == "Product Status Updated Successfully!")
    Swal.fire({
      title: "Success",
      text: "Product Status Updated Successfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.outofstockProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}
function* handleBulkUpdate(action) {
  console.log(action,'uyguyg')
  try {
    const response = yield call(bulkCreateProductApi, action.payload);
    yield put(productAction.postBulkProductSuccess(response));
    console.log(response, "5trgybb");
    if(response?.data?.message === "Bulk Product Update Completed!")
    Swal.fire({
      title: "Success",
      text: "Product Bulk Upload Succesfully!",
      icon: "success",
    });
  } catch (e) {
    yield put(productAction.postBulkProductFailure(e));
    Swal.fire({
      title: "Error",
      text: e?.response?.data?.message,
      icon: "error",
    });
  }
}


export function* productSaga() {
  yield takeEvery(
    productActionTypes.POSTPRODUCT_REQUEST,
    handlepostProductData
  );
  yield takeEvery(
    productActionTypes.GETPRODUCT_REQUEST,
    handlegetProductData
  );
  yield takeEvery(
    productActionTypes.UPDATEPRODUCT_REQUEST,
    handleUpdateProductData
  );

  yield takeEvery(
    productActionTypes.DELETEPRODUCT_REQUEST,
    handleDeleteProductData
  );
  yield takeEvery(
    productActionTypes.POSTPRODUCTPRICE_REQUEST,
    handlepostProductDataPrice
  );
  yield takeEvery(
    productActionTypes.POSTPRODUCTSTOCK_REQUEST,
    handlepostProductStockData
  );
  yield takeEvery(
    productActionTypes.GETPRODUCTSTOCK_REQUEST,
    handlegetProductStockData
  );
  yield takeEvery(
    productActionTypes.UPDATEPRODUCTSTOCK_REQUEST,
    handleUpdateProductStockData
  );
  yield takeEvery(
    productActionTypes.OUTOFSTOCKPRODUCT_REQUEST,
    handleOutofStock
  );
  yield takeEvery(productActionTypes.POSTBULKPRODUCT_REQUEST, handleBulkUpdate);

}

import { all } from "redux-saga/effects";
import { AuthSaga } from "./auth/auth.saga";
import { AdminsettingSaga } from "./settings/settings.saga";
import { UserSaga } from "./user/user.saga";
import { CategoryAddSaga } from "./category/category.saga";
import { BrandSaga } from "./brand/brand.saga";
import { productSaga } from "./product/product.saga";
import { orderSaga } from "./order/order.saga";
import { InventrySaga } from "./InventryScreen/Saga";
import { dashboardSaga } from "./dashboard/reducer.saga";
import { VendorSaga } from "./vendor/vendor.saga";
import { CouponSaga } from "./coupon/coupon.saga";
import { CompanySaga } from "./company/company.saga";
import { VegSaga } from "./veg/veg.saga";
import { MarketSaga } from "./market/market.saga";
import { TransportSaga } from "./transport/transport.saga";
// import { CategoryAddSaga } from "../components/AdminPanel/Saga";
// import { AdminsettingSaga } from "../components/Setting/Saga";
// import { InventrySaga } from "../components/InventryScreen/Saga";

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    AdminsettingSaga(),
    UserSaga(),
    CategoryAddSaga(),
    BrandSaga(),
    productSaga(),
    orderSaga(),
    InventrySaga(),
    dashboardSaga(),
    VendorSaga(),
    CouponSaga() ,
    CompanySaga() ,
    VegSaga(),
    MarketSaga(),
    TransportSaga(),
  ]);
}

import { transportActionType } from "./transport.model";
import { action } from "typesafe-actions";

export const getTransportRequest=(req)=>
    action(transportActionType.GETTRANSPORT_REQUEST,req);
export const getTransportSuccess=(res)=>
    action(transportActionType.GETTRANSPORT_SUCCESS,res);
export const getTransportFailure=(err)=>
    action(transportActionType.GETTRANSPORT_FAILURE,err);

export const postTransportRequest=(req)=>
    action(transportActionType.POSTTRANSPORT_REQUEST,req);
export const postTransportSuccess=(res)=>
    action(transportActionType.POSTTRANSPORT_SUCCESS,res);
export const postTransportFailure=(err)=>
    action(transportActionType.POSTTRANSPORT_FAILURE,err);

export const updateTransportRequest=(req)=>
    action(transportActionType.UPDATETRANSPORT_REQUEST,req);
export const updateTransportSuccess=(res)=>
    action(transportActionType.UPDATETRANSPORT_SUCCESS,res);
export const updateTransportFailure=(err)=>
    action(transportActionType.UPDATETRANSPORT_FAILURE,err);

export const getVehicelRequest=(req)=>
    action(transportActionType.GETVEHICLE_REQUEST,req);
export const getVehicelSuccess=(res)=>
    action(transportActionType.GETVEHICLE_SUCCESS,res);
export const getVehicelFailure=(err)=>
    action(transportActionType.GETVEHICLE_FAILURE,err);
import { action } from "typesafe-actions";
import { marketAddActionType } from "./market.model";



export const postMarketRequest=(req) =>
    action(marketAddActionType.POSTMARKET_REQUEST,req)
export const postMarketSuccess=(res)=>
    action(marketAddActionType.POSTMARKET_SUCCESS,res)
export const postMarketFailure=(err)=>
    action(marketAddActionType.POSTMARKET_FAILURE,err)


export const getMarketRequest=(req) =>
    action(marketAddActionType.GETMARKET_REQUEST,req)
export const getMarketSuccess=(res)=>
    action(marketAddActionType.GETMARKET_SUCCESS,res)
export const getMarketFailure=(err)=>
    action(marketAddActionType.GETMARKET_FAILURE,err)


export const updateMarketRequest=(req) =>
    action(marketAddActionType.UPDATEMARKET_REQUEST,req)
export const updateMarketSuccess=(res)=>
    action(marketAddActionType.UPDATEMARKET_SUCCESS,res)
export const updateMarketFailure=(err)=>
    action(marketAddActionType.UPDATEMARKET_FAILURE,err)

export const deleteMarketRequest=(req) =>
    action(marketAddActionType.DELETEMARKET_REQUEST,req)
export const deleteMarketSuccess=(res)=>
    action(marketAddActionType.DELETEMARKET_SUCCESS,res)
export const deleteMarketFailure=(err)=>
    action(marketAddActionType.DELETEMARKET_FAILURE,err)

export const postMarketPriceRequest=(req) =>
    action(marketAddActionType.POSTMARKETPRICE_REQUEST,req)
export const postMarketPriceSuccess=(res)=>
    action(marketAddActionType.POSTMARKETPRICE_SUCCESS,res)
export const postMarketPriceFailure=(err)=>
    action(marketAddActionType.POSTMARKETPRICE_FAILURE,err)

export const getMarketPriceRequest=(req) =>
    action(marketAddActionType.GETMARKETPRICE_REQUEST,req)
export const getMarketPriceSuccess=(res)=>
    action(marketAddActionType.GETMARKETPRICE_SUCCESS,res)
export const getMarketPriceFailure=(err)=>
    action(marketAddActionType.GETMARKETPRICE_FAILURE,err)

export const resetMarket=(req)=>
    action(marketAddActionType.RESETMARKET, req)
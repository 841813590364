import { decryptdata } from "../../utils/encrypt&decrypt";
import { transportActionType, transportInitialState } from "./transport.model";

const reducer = (state = transportInitialState, action) => {
  switch (action.type) {
    case transportActionType.GETTRANSPORT_REQUEST:
      return {
        ...state,
        getTransportLoading: true,
      };
    case transportActionType.GETTRANSPORT_SUCCESS:
      console.log(action?.payload?.data?.data, process.env.SECRET_KEY, "jkmls");
     
      return {
        ...state,
        getTransportLoading: false,
        transport:action?.payload?.data?.data,
      };
    case transportActionType.GETTRANSPORT_FAILURE:
      return {
        ...state,
        getTransportLoading: false,
      };

    case transportActionType.POSTTRANSPORT_REQUEST:
      return {
        ...state,
        postTranpostLoading: true,
        postTranpostSuccess: false,
      };
    case transportActionType.POSTTRANSPORT_SUCCESS:
      return {
        ...state,
        postTranpostLoading: false,
        postTranpostSuccess: true,
      };
    case transportActionType.POSTTRANSPORT_FAILURE:
      return {
        ...state,
        postTranpostLoading: false,
        postTranpostSuccess: false,
      };

    case transportActionType.UPDATETRANSPORT_REQUEST:
      return {
        ...state,
        updateTranpostLoading: true,
        updateTranpostSuccess: false,
      };
    case transportActionType.UPDATETRANSPORT_SUCCESS:
      return {
        ...state,
        updateTranpostLoading: false,
        updateTranpostSuccess: true,
      };
    case transportActionType.UPDATETRANSPORT_FAILURE:
      return {
        ...state,
        updateTranpostLoading: false,
        updateTranpostSuccess: false,
      };
      case transportActionType.GETVEHICLE_REQUEST:
        return {
          ...state,
          getVechileLoading: true,
        };
      case transportActionType.GETVEHICLE_SUCCESS:
    
       
        console.log(action?.payload?.data?.data, "qwer");
        return {
          ...state,
         
          getVechileLoading:false,
          vehicle:action?.payload?.data?.data,
        };
      case transportActionType.GETVEHICLE_FAILURE:
        return {
          ...state,
          getVechileLoading: false,
        };
  
    default:
      return state;
  }
};

export { reducer as transportReducer };

export const transportSelector = (state) => state.transportReducer;


export const companyAddActionType={
    POSTCOMPANY_REQUEST:"@@company/POSTCOMPANY_REQUEST",
    POSTCOMPANY_SUCCESS:"@@company/POSTCOMPANY_SUCCESS",
    POSTCOMPANY_FAILURE:"@@company/POSTCOMPANY_FAILURE",

    GETCOMPANY_REQUEST:"@@company/GETCOMPANY_REQUEST",
    GETCOMPANY_SUCCESS:"@@company/GETCOMPANY_SUCCESS",
    GETCOMPANY_FAILURE:"@@company/GETCOMPANY_FAILURE",

    UPDATECOMPANY_REQUEST:"@@company/UPDATECOMPANY_REQUEST",
    UPDATECOMPANY_SUCCESS:"@@company/UPDATECOMPANY_SUCCESS",
    UPDATECOMPANY_FAILURE:"@@company/UPDATECOMPANY_FAILURE",

    DELETECOMPANY_REQUEST:"@@company/DELETECOMPANY_REQUEST",
    DELETECOMPANY_SUCCESS:"@@company/DELETECOMPANY_SUCCESS",
    DELETECOMPANY_FAILURE:"@@company/DELETECOMPANY_FAILURE",

    GETCOMPANYUSER_REQUEST:"@@company/GETCOMPANYUSER_REQUEST",
    GETCOMPANYUSER_SUCCESS:"@@company/GETCOMPANYUSER_SUCCESS",
    GETCOMPANYUSER_FAILURE:"@@company/GETCOMPANYUSER_FAILURE",

    RESETCOMPANY:"@@/company/RESETCOMPANY"
}
export const companyInitalState={
    postLoading:false,
    postSuccess:false,
    getLoading:false,
    allCompany:[],
    updateLoading:false,
    updateSuccess:false,
    deleteLoading:false,
    deleteSuccess:false,
    getUserLoading:false,
    Companyuser:[],
    CompanyUser_id:null,
}
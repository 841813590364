import { decryptdata } from "../../utils/encrypt&decrypt";
import { settingActionType, settingInitialState } from "./settings.model";

const reducer = (state = settingInitialState, action) => {
  switch (action?.type) {
    case settingActionType.POSTBANNERDETAIL_REQUEST:
      return {
        ...state,
        loading: true,
        postMessage:false
      };
    case settingActionType.POSTBANNERDETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        postMessage:true
      };
    case settingActionType.POSTBANNERDETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        postMessage:false
      };
    case settingActionType.POSTSETTINGDATA_REQUEST:
      return {
        ...state,
        postLoading: true,
        sendsettingSuccess: false,
      };
    case settingActionType.POSTSETTINGDATA_SUCCESS:
      return {
        ...state,
        postLoading: false,
        sendsettingSuccess: true,
      };
    case settingActionType.POSTSETTINGDATA_FAILURE:
      return {
        ...state,
        postLoading: false,
        sendsettingSuccess: false,
      };
    case settingActionType.GETSETTINGDATA_REQUEST:
      return {
        ...state,
        getLoading: true,
        getdataSuccess: false,
      };
    case settingActionType.GETSETTINGDATA_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas3 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas3[0], "yhoioassdfd");
      return {
        ...state,
        getLoading: false,
        getSettingData: payloaddatas3[0],
        getdataSuccess: true,
      };
    case settingActionType.GETSETTINGDATA_FAILURE:
      return {
        ...state,
        getLoading: false,
        getdataSuccess: false,
      };
    case settingActionType.GETBANNERDATA_REQUEST:
      return {
        ...state,
        bannerLoad: true,
        bannerDataSucess:false
      };
    case settingActionType.GETBANNERDATA_SUCCESS:
      console.log(action?.payload, "34rtgvhb n")
      let payloaddatas4 = JSON.parse(
        decryptdata(
          action?.payload?.data?.data,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas4, "76yguiigiuy")
      return {
        ...state,
        bannerLoad: false,
        bannerData: payloaddatas4?.data?.[0],
        bannerDataSucess:true
      };
    case settingActionType.GETBANNERDATA_FAILURE:
      return {
        ...state,
        bannerLoad: false,
        bannerDataSucess:false,
      };
    default:
      return state;
  }
};
export const  SettingSelector=(state)=>state.AdminSettingReducer
export { reducer as AdminSettingReducer };


import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const PostCompanyApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/b2bcompany/create/",
    formData,
    Http.getFileAuthToken()
  );
};

export const GetCompanyApi = (query) => {
 

  const baseUrl = getBaseEndpointUrl();
  console.log(query,"yes");
  // return Http.get(baseUrl + "/b2bcompany/", Http.getAuthToken());
  return Http.get(
    baseUrl +
      "/b2bcompany?query=" +
      query.query +
      "&b2bid=" +
      query.b2b_id +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit,
    
    Http.getAuthToken()
  );
};
export const updateCompanyApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/b2bcompany/update/" + formData?.id,
    formData,
    Http.getFileAuthToken()
  );
};

export const deleteCompanyApi = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(baseUrl + "/b2bcompany/" + id, Http.getAuthToken());
};


export const GetCompanyUserApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(query,"yes");
  
  return Http.get(
    baseUrl +
      "/b2bcompany/byId/" +query.id,
      
    
    Http.getAuthToken()
  );
};
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import item1 from "../../assets/imgs/items/1.jpg";
import item2 from "../../assets/imgs/items/2.jpg";
import item3 from "../../assets/imgs/items/3.jpg";
import item4 from "../../assets/imgs/items/4.jpg";
import item5 from "../../assets/imgs/items/5.jpg";
import item6 from "../../assets/imgs/items/6.jpg";
import ExcelSample from "../../assets/excel/SampleExcel.xlsx";
import { read, utils, writeFile } from "xlsx";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteProductRequest,
  getProductRequest,
  outofstockProductRequest,
  postBulkProductRequest,
  productStateResetRequest,
} from "../../store/product/product.action";
import { useEffect } from "react";
import { productSelector } from "../../store/product/product.model";
import { API_BASE } from "../../services/config";
import { useState } from "react";
import noImg from "../../assets/imgs/no-image.jpg";
import { Modal } from "react-bootstrap";
import { Button } from "rsuite";
function Pageproductslist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Selectors */
  const { getProductData, productDeleteSucces } = useSelector(productSelector);
  console.log(getProductData?.data, "34567");
  /* State */
  // const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [importErr, setImportErr] = useState({ err: false, msg: "" });
  const [uploadbtn,setuploadbtn] = useState(true)
  const [importSuccess, setImportSuccess] = useState({
    success: false,
    msg: "",
  });
  const [array, setArray] = useState([]);

  /* Function */
  const totalPages1 = Math.ceil(getProductData?.count / rowsPerPage) || 1;

  const handleDelete = (id) => {
    dispatch(deleteProductRequest(id));
  };

  const handleoutofstock = (status, id) => {
    dispatch(outofstockProductRequest({ out_of_stock: status, id: id }));
  };
  const isFound = (list, check) => {
    console.log(list, check, "lisdfdfst");
    console.log(check);
    let checked = list.filter((element) => {
      if (element.name === check) {
        console.log(element.id, "uyfyu");
        return element.id;
      }
      return false;
    });
    console.log(checked, "checked");
    return checked;
  };
  const handleShow = () =>{
    console.log("entered");
    setShow(true);}
  const handleClose = () => {
    setShow(false);
    setErrors({});
    setImportErr({ err: false, msg: "" });
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const fileBuffer = await file.arrayBuffer();
    const workbook = read(fileBuffer, {
      type: "string",
      raw: true,
      dateNF: "dd/MM/yyyy",
    });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const data = utils.sheet_to_json(worksheet, { raw: false });

    if (data.length === 0) {
      setErrors({ file: ["Uploaded file is empty"] });
      setImportErr({ err: true, msg: "The uploaded file is empty" });
      event.target.value = null;
      setuploadbtn(true)
      return;
    }
    setuploadbtn(false);
    setErrors({});
    setImportErr({ err: false, msg: "" });

    const newErrors = {};

    const validateField = (item, field, row, errorKey, errorMsg) => {
      if (!item[field]) {
        newErrors[errorKey] = newErrors[errorKey] || [];
        newErrors[errorKey].push(`${errorMsg} in row ${row}`);
        return false;
      }
      return true;
    };

    const arrayObj = data
      .map((item, i) => {
        console.log(item["Product Name"], "cuygau");
        const row_no = i + 2;

        if (
          !validateField(
            item,
            "Product Name",
            row_no,
            "productName",
            "Product Name is missing"
          )
        ) {
          return null;
        }
        if (
          !validateField(
            item,
            "Retail Rate",
            row_no,
            "retailRate",
            "Retail Rate is missing"
          )
        ) {
          return null;
        }
        if (
          !validateField(
            item,
            "Sale Rate",
            row_no,
            "saleRate",
            "Sale Rate is missing"
          )
        ) {
          return null;
        }
        const Productidcheck = isFound(
          getProductData?.data,
          item["Product Name"]
        );
        console.log(Productidcheck, "dafcuyg");
        if (Productidcheck.length === 0) {
          newErrors.ProductName = newErrors.ProductName || [];
          newErrors.ProductName.push(`Invalid Product Name in row ${row_no}`);
          return null;
        }

        const Productid = Productidcheck[0].id;

        const transformedItem = {
          product_name: Productid,
          retail_rate: item["Retail Rate"],
          sale_rate: item["Sale Rate"],
        };

        return transformedItem;
      })
      .filter((item) => item !== null);

    if (Object.keys(newErrors).length > 0) {
      event.target.value = null;
      setErrors(newErrors);
      setImportErr({ err: true, msg: "Errors found in the file" });
    } else {
      setImportSuccess({ success: true, msg: "Validation success!" });
      setArray(arrayObj);
    }

    console.log(arrayObj, "Transformed array of objects");
  };

  const handleUplad = () => {
    let payload = {
      datas: array,
    };
    dispatch(postBulkProductRequest(payload));
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const currentPage = page; // Replace with your actual current page state
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  /* useEffect */
  useEffect(() => {
    const payload = {
      query: searchQuery,
      prod_id: 0,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getProductRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage]);
  useEffect(() => {
    if (productDeleteSucces) {
      const payload = {
        query: "",
        prod_id: 0,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getProductRequest(payload));
      dispatch(productStateResetRequest());
    }
  }, [productDeleteSucces]);
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Products List</h2>
              {/* <p>Lorem ipsum dolor sit amet.</p> */}
            </div>
            <div>
              {/* <button
                className="btn btn-primary btn-sm rounded me-3"
                onClick={handleShow}
              >
                {" "}
                Bulk Upload
              </button>

              <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Bulk Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex flex-column ">
                    <label className="mb-2"> Download Sample file</label>

                    <a href={ExcelSample} className="mb-2" download>
                      <button className="btn btn-primary">
                        Download Excel File
                      </button>
                    </a>
                    <label className="mb-2"> Upload file</label>

                    <input
                      className="form-control mb-2"
                      type="file"
                      onChange={handleFileUpload}
                      accept=".xlsx, .xls"
                    />
                    <p className="text-danger">
                      {importErr && importErr.err == true ? importErr.msg : ""}
                    </p>
                    <p className="text-danger">{errors.ProductName}</p>
                    <p className="text-danger">{errors.RetailRate}</p>
                    <p className="text-danger">{errors.SaleRate}</p>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={handleUplad} disabled={uploadbtn}>
                    Upload
                  </Button>
                </Modal.Footer>
              </Modal> */}
              <button
                className="btn btn-primary btn-sm rounded"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(productStateResetRequest());
                  navigate("/product/add");
                }}
              >
                {" "}
                Create new
              </button>
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  {/* <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div> */}
                </div>
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  {/* <select className="form-select">
                    <option selected="">All category</option>
                    <option>Switches</option>
                    <option>Adapter</option>
                    <option>Panels</option>
                    <option>Wires</option>
                  </select> */}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-md-2 col-6">
                  <select className="form-select">
                    <option selected="">Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Show all</option>
                  </select>
                </div>
                {/* <div className="col-md-2 col-6">
                  <input
                    className="form-control"
                    type="date"
                    value="02.05.2022"
                  />
                </div> */}
                <div className="col-md-2 col-6">
                  <select
                    className="form-select"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value={10}>Show 10</option>
                    <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                  </select>
                </div>
              </div>
            </header>

            <div className="card-body">
              <article className="itemlist">
                <div className="row align-items-center">
                  {/* <div className="col col-check flex-grow-0">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </div> */}
                  <div className="col-lg-1 col-sm-2 col-4 col-price   ">
                    <h6 className="mb-0">Image</h6>
                  </div>

                  <div className="col-lg-2 col-sm-4 col-4 col-name flex-grow-1 px-4 ms-4 ps-5">
                    <h6 className="mb-0 ">Product Name</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-status flex-grow-1 ">
                    <h6 className="mb-0">Category Name</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Regular Price</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Sale Price</h6>
                  </div>

                  <div className="col-lg-1 col-sm-2 col-4 col-date">
                    <h6 className="mb-0">Created Date</h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4">
                    <h6 className="mb-0">
                      Current <br></br>Status{" "}
                    </h6>
                  </div>
                  <div className="col-lg-1 col-sm-2 col-4">
                    <h6 className="mb-0">
                      Status <br></br>Management
                    </h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-action text-center">
                    <h6 className="mb-0 ms-5">Action</h6>
                  </div>
                </div>
              </article>

              {getProductData?.data && getProductData?.data?.length > 0 ? (
                getProductData?.data.map((product, index) => (
                  <article className="itemlist" key={product.id}>
                    <div className="row align-items-center">
                      {/* <div className="col col-check flex-grow-0">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" />
                        </div>
       
       </div> */}
                      <div className="col-lg-1 col-sm-4 col-4  col-name">
                        <div className="left">
                          <img
                            className="img-sm img-thumbnail"
                            src={
                              product?.image ? API_BASE + product.image : noImg
                            }
                            alt="Item"
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 col-sm-2 col-4 col-price flex-grow-1 text-center">
                        {/* <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        > */}
                          {/* <div className="info"> */}
                            <h6 className="mb-0">{product.name}</h6>
                          {/* </div> */}
                        {/* </a> */}
                      </div>
                      <div className="col-lg-1 col-sm-2 col-4 col-price flex-grow-1 text-center">
                        <span> {product.category.name}</span>
                      </div>{" "}
                      <div className="col-lg-1 col-sm-2 col-4 col-price">
                        <span>₹{product.reg_price}</span>
                      </div>
                      <div className="col-lg-1 col-sm-2 col-4 col-price">
                        <span>₹{product.sale_price}</span>
                      </div>
                      <div className="col-lg-1 col-sm-2 col-4 col-date">
                        <span>
                          {new Date(product.created_at).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="col-lg-1 col-sm-2 col-4 col-date">
                        <span className="badge rounded-pill alert-danger">
                          {product?.out_of_stock == 0 && "Out of Stock"}
                        </span>
                        <span className="badge rounded-pill alert-dark">
                          {product?.out_of_stock == 1 && "Product Hided"}
                        </span>
                        <span className="badge rounded-pill alert-success">
                          {product?.out_of_stock == 2 && "Product available"}
                        </span>
                      </div>
                      {console.log(product, "ytfyug")}
                      <div className="col-lg-1 col-sm-2 col-4 col-date">
                        {/* <span> */}
                        {product?.out_of_stock == 0 && (
                          <select
                            name=""
                            className="form-select w-100"
                            onChange={(e) =>
                              handleoutofstock(e.target.value, product?.id)
                            }
                          >
                            <option className="option" value={""}>
                              SELECT
                            </option>
                            <option className="option" value={1}>
                              HIDE
                            </option>
                            <option className="option" value={2}>
                              STOCK AVAILABLE
                            </option>
                          </select>
                        )}
                        {product.out_of_stock == 1 && (
                          <select
                            name=""
                            className="form-select w-100"
                            onChange={(e) =>
                              handleoutofstock(e.target.value, product?.id)
                            }
                          >
                            <option className="option" value={""}>
                              SELECT
                            </option>
                            <option className="option" value={0}>
                              OUT OF STOCK
                            </option>
                            <option className="option" value={2}>
                              STOCK AVAILABLE
                            </option>
                          </select>
                        )}

                        {product?.out_of_stock == 2 && (
                          <select
                            name=""
                            className="form-select w-100"
                            onChange={(e) =>
                              handleoutofstock(e.target.value, product?.id)
                            }
                          >
                            <option className="option" value={""}>
                              SELECT
                            </option>
                            <option className="option" value={0}>
                              OUT OF STOCK
                            </option>
                            <option className="option" value={1}>
                              HIDE
                            </option>
                          </select>
                        )}
                        {/* <select
                          name=""
                          className="form-select w-100"
                          onChange={(e) =>
                            handleoutofstock(e.target.value, product?.id)
                          }
                        >
                          <option className="option" value={""}>
                            SELECT
                          </option>
                          <option className="option" value={0}>
                            OUT OF STOCK
                          </option>
                          <option className="option" value={1}>
                            HIDE
                          </option>
                          <option className="option" value={2}>
                            STOCK AVAILABLE
                          </option>
                        </select> */}
                        {/* </span> */}
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                        <button
                          className="btn btn-sm font-sm rounded btn-brand mr-5"
                          onClick={() =>
                            navigate(`/product/edit/${product?.id}`)
                          }
                        >
                          <i className="material-icons md-edit"></i> Edit
                        </button>
                        <button
                          className="btn btn-sm font-sm btn-light rounded"
                          onClick={() => handleDelete(product.id)}
                        >
                          <i className="material-icons md-delete_forever"></i>{" "}
                          Delete
                        </button>
                      </div>
                    </div>
                  </article>
                ))
              ) : (
                <p>No products found</p>
              )}
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {pagesToDisplay.map((page) => {
                  return page != "..." ? (
                    <li
                      key={page}
                      className={`page-item b ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ) : page == "..." ? (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ) : (
                    ""
                  );
                })}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default Pageproductslist;

import React, { useEffect, useState } from "react";
import "rsuite/DateRangePicker/styles/index.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";


import { useDispatch, useSelector } from "react-redux";
import "react-multi-email/dist/style.css";
import { useNavigate, useParams } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";



import Header from "../Header";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { createSchema } from "../../utils/Validation";
import { getCompanyRequest, postCompanyRequest, resetCompany, updateCompanyRequest } from "../../store/company/company.action";
import { CompanySelector } from "../../store/company/company.reducer";
import { API_BASE } from "../../services/config";

function CompanyAdd() {
  //state

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [imgurl, setImgurl] = useState(null);
  const [img, setImg] = useState(null);
  const MySwal = withReactContent(Swal);
  const { postSuccess, updateSuccess } = useSelector(CompanySelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(createSchema),
  });
  console.log(errors, "errors");

  const { allCompany } = useSelector(CompanySelector);

  //functions
  const hangelimg = (e) => {
    // console.log(e.target.files[0]);
    const imgs = e.target.files[0];
    console.log(imgs,"zxc")
    if (imgs) {
      setImgurl(imgs);
      setImg(URL.createObjectURL(imgs));
      console.log(img,"zxc")
    }
  };

  const onSubmithandler = (formData) => {
    console.log(imgurl, "edc");
    formData.logo = imgurl;
    console.log(formData, "edc");
    if (id) {
      formData.id = id;
      MySwal.fire({
        title: " Edit the Company Detials??",
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(updateCompanyRequest(formData));
        }
      });
    } else {
      // MySwal.fire({
      //     title: "Would You Like To Add Company??",
      //     showCancelButton: true,
      //     focusConfirm: false,
      //     confirmButtonText: "Ok",
      //     cancelButtonText: "Cancel",
      //   }).then((result)=>{
      //     if(result.isConfirmed){
      //         dispatch(postCompanyRequest(formData))
      //     }
      //   })
      dispatch(postCompanyRequest(formData));
    }
  };

  //useEffects
  useEffect(() => {
    if (id) {
      dispatch(getCompanyRequest());
    }
  }, [id]);

  // console.log(id,'zxc');
  useEffect(() => {
    if (id) {
      console.log(id, "zxc");
      let sel = allCompany?.data?.find((c) => c.id == id);
      console.log(sel, "zxc");
      // setImg(URL.createObjectURL(sel?.image ? API_BASE + sel.image : "noImg")) 
      setImg(API_BASE+sel.logo)
      // console.log(URL.createObjectURL(sel.logo));
      console.log(sel.logo,'zxc','x');
      
      reset({
        companyName: sel.companyName,
        contactName: sel.contactName,
        contactNumber: sel.contactNumber,
        email: sel.email,
        password: "",
       
        address: sel.address ? sel.address : "",
        city: sel.city ? sel.city : "",
        state: sel.state ? sel.state : "",
        zipcode: sel.zipcode ? sel.zipcode : "",
        gstNo: sel.gstNo ? sel.gstNo : "",
      });
    }
  }, [id]);
  useEffect(() => {
    if (postSuccess) {
      dispatch(resetCompany());
      navigate("/company");
    }
    if (updateSuccess) {
      dispatch(resetCompany());
      navigate("/company");
    }
  }, [postSuccess, updateSuccess]);
  console.log(postSuccess, "ghgh");

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <form onSubmit={handleSubmit(onSubmithandler)}>
            <div className="content-header">
              <h2 className="content-title">
                {id ? "Edit Company" : "Add Company"}
              </h2>
            </div>
            <div className="content-body">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="companyName">
                        Company Name
                      </label>
                      <input
                        className="form-control"
                        id=" companyName"
                        type="text"
                        {...register("companyName")}
                        placeholder="Enter  company_name"
                      />
                      {errors?.companyName && (
                        <p className="text-danger">
                          {errors?.companyName?.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="contactName">
                        Contact Name
                      </label>
                      <input
                        className="form-control"
                        id="contactName"
                        type="text"
                        {...register("contactName")}
                        placeholder="Enter  contactName"
                      />
                      {errors?.contactName && (
                        <p className="text-danger">
                          {errors?.contactName?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="contactNumber">
                        Contact Number
                      </label>
                      <input
                        className="form-control"
                        id="contactNumber"
                        type="text"
                        {...register("contactNumber")}
                        placeholder="Enter  contactNumber"
                      />
                      {errors?.contactNumber && (
                        <p className="text-danger">
                          {errors?.contactNumber?.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        className="form-control"
                        id="email"
                        type="email"
                        {...register("email")}
                        placeholder="Enter email"
                      />
                      {errors?.email && (
                        <p className="text-danger">{errors?.email?.message}</p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="password">
                        password
                      </label>
                      <input
                        className="form-control"
                        id="password"
                        type="password"
                        {...register("password")}
                        placeholder="Enter password "
                      />

                      {errors?.password && (
                        <p className="text-danger">
                          {errors?.password?.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <div className="row">
                        <div className="col-8">
                          <label className="form-label" htmlFor="logo">
                            logo
                          </label>
                          <input
                            className="form-control"
                            id="logo"
                            type="file"
                            // value={imgurl}
                            onChange={hangelimg}
                          />
                          {errors?.logo && (
                            <p className="text-danger">
                              {errors?.logo?.message}
                            </p>
                          )}
                        </div>

                        {img && (
                          
                          
                          <div className="col-4">
                            {console.log(API_BASE+img,"zxcv")}
                            <label className="form-label" htmlFor="logo">
                              Review
                            </label><br />
                            <img src={img} alt="no" style={{height:'80px',width:'80%'}}/>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="email_data">
                        Address
                      </label>

                      <textarea
                        name="address"
                        id="address"
                        className="form-control"
                        {...register("address")}
                        placeholder="Enter Address"
                      ></textarea>
                      {errors?.address && (
                        <p className="text-danger">
                          {errors?.address?.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="city">
                        City
                      </label>
                      <input
                        className="form-control"
                        id="city"
                        type="text"
                        {...register("city")}
                        placeholder="Enter city"
                      />
                      {errors?.city && (
                        <p className="text-danger">{errors?.city?.message}</p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="state">
                        state
                      </label>
                      <input
                        className="form-control"
                        id="state"
                        type="text"
                        {...register("state")}
                        placeholder="Enter state"
                      />
                      {errors?.state && (
                        <p className="text-danger">{errors?.state?.message}</p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="zipcode">
                        zipcode
                      </label>
                      <input
                        className="form-control"
                        id="zipcode"
                        type="text"
                        {...register("zipcode")}
                        placeholder="Enter zipcode"
                      />
                      {errors?.zipcode && (
                        <p className="text-danger">
                          {errors?.zipcode?.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="gstNo">
                        GST Number
                      </label>
                      <input
                        className="form-control"
                        id="gstNo"
                        type="text"
                        {...register("gstNo")}
                        placeholder="Enter GST No"
                      />
                      {errors?.gstNo && (
                        <p className="text-danger">{errors?.gstNo?.message}</p>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-4"></div>
                      <div className="col-lg-6 mb-4 d-flex justify-content-end ">
                        <input
                          type="submit"
                          value="submit"
                          className="btn btn-md rounded font-sm hover-up"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default CompanyAdd;

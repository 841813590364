import { Http } from "../../services/Http";
import { getBaseEndpointUrl } from "../../services/config";

export const PostMarketApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "/market/", formData, Http.getAuthToken());
};

export const GetMarketApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  console.log(query, "yes");

  return Http.get(
    baseUrl +
      "/market?query=" +
      query.query +
      "&id=" +
      query.id +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit,

    Http.getAuthToken()
  );
};
export const updateMarketApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.put(
    baseUrl + "/market/" + formData?.id,
    formData,
    Http.getFileAuthToken()
  );
};

export const deleteMarketApi = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(baseUrl + "/market/" + id, Http.getAuthToken());
};

export const PostMarketPriceApi = (formData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/b2bprodDetails/",
    formData,
    Http.getAuthToken()
  );
};

export const GetMarketPriceApi = (query) => {
 

  const baseUrl = getBaseEndpointUrl();
  console.log(query,"yes");
  
  return Http.get(
    baseUrl +
      "/b2bprodDetails?query=" +
      query.query +
      "&market_id=" +
      query.market_id +
      "&page=" +
      query.page +
      "&limit=" +
      query.limit+
      "&b2b_user_id="+
      query.b2b_user_id,
    
    Http.getAuthToken()
  );
};
import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Http";

export const postVegtApi = (formData) => {
  const abortController = new AbortController();
  const prop = { signal: abortController.signal };
  const baseUrl = getBaseEndpointUrl();
  return Http.post(
    baseUrl + "/b2bproductselect/",
    formData,
    Http.getAuthToken()
  );
};
export const getVegApi = (query) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(
    baseUrl +
      "/b2bproductselect?query=" +
      query.query +
      "&id="+
      query.id+
      "&page=" +
      query.page +
      "&limit=" +
      query.limit,
    Http.getAuthToken()
  );
};

export const deleteVegApi = (id) => {
  console.log(id,'qazx');
  
  const baseUrl = getBaseEndpointUrl();
  return Http.delete(baseUrl + "/b2bproductselect/" + id, Http.getAuthToken());
};

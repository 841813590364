import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import { SettingValidation } from '../../utils/Validation';
import { useDispatch,useSelector } from 'react-redux';
import { getSettingDataRequest, postSettingDataRequest } from '../../store/settings/settings.action';
import { AdminSettingReducer, SettingSelector } from '../../store/settings/settings.reducer';
import { settingInitialState } from '../../store/settings/settings.model';

// import { getusergroupRequest } from '../../store/settings/settings.action';

function Setting() {
    const {SettingState}=useSelector(AdminSettingReducer)
//    console.log(settingInitialState,"ppp")
    const dispatch=useDispatch();
    const {getSettingData}=useSelector(SettingSelector)
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
      } = useForm({
        resolver: yupResolver(SettingValidation),
        
      });
      
  const onSubmitHandler = (formData) => {
    dispatch(postSettingDataRequest(formData));
    console.log(formData, "4567yuhjkm");
   
   
    
  };
  useEffect(()=>{
    dispatch(getSettingDataRequest({}))
  },[])
  //getSettingData
  useEffect(()=>{
    console.log(getSettingData,"getSettingData");
    if(getSettingData){
      reset({
        tax:getSettingData.tax,
        delivery_text: getSettingData.delivery_text,
        address: getSettingData.address,
        contact:getSettingData.contact,
        contact_gmail: getSettingData.contact_gmail,
        gmail:getSettingData.gmail,
        map_url: getSettingData.map_url,
        delivery_percent: getSettingData.delivery_percent,
        max_range:getSettingData.max_range,
        min_range: getSettingData.min_range,
      })
    }
    
  },[getSettingData])
  return (
    <div>
         <Form 
          onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Row>
             

              <Col lg={12}>
                {/* <div className="card mb-4"> */}
                 
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="name">
                          Gmail
                        </label>
                        <input
                          className="form-control"
                          id="gmail"
                          type="gmail"
                          {...register("gmail")}
                          placeholder="Enter your gmail"
                        />
                        {errors?.gmail && (
                          <p className="text-danger">{errors?.gmail?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="contact_gmail">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="contact_gmail"
                          type="email"
                          {...register("contact_gmail")}
                          placeholder="Enter your contact_gmail"
                        />
                        {errors?.contact_gmail && (
                          <p className="text-danger">
                            {errors?.contact_gmail?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="contact">
                          Contact
                        </label>
                        <input
                          className="form-control"
                          id="contact"
                          type="tel"
                          {...register("contact")}
                          placeholder="Enter your contact number"
                        />
                        {errors?.contact && (
                          <p className="text-danger">
                            {errors?.contact?.message}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        {/* <label className="form-label" htmlFor="password">
                          Alternate Mobile
                        </label>
                        <input
                          className="form-control"
                          id="alt_mobile"
                          type="text"
                          {...register("alt_mobile")}
                          placeholder="Enter your Alternate Mobile"
                        />
                        {errors?.password && (
                          <p className="text-danger">
                            {errors?.password?.message}
                          </p>
                        )} */}
                        <label className="form-label" htmlFor="address">
                          Address 
                        </label>
                        <input
                          className="form-control"
                          id="address"
                          type="text"
                          {...register("address")}
                          placeholder="Enter your address "
                        />
                        {/* <textarea
                          className="form-control"
                          id="address"
                          {...register("address")}
                          placeholder="Enter your address"
                          rows="3"
                        ></textarea> */}
                        {errors?.address && (
                          <p className="text-danger">
                            {errors?.address?.message}
                          </p>
                        )}
                      </div>
                    </div>
                    
                    <div className="row">
                    <div className="col-lg-6 mb-4">
                    <label className="form-label" htmlFor="delivery_text">
                         Delivery Text
                        </label>
                        <input
                          className="form-control"
                          id="delivery_text"
                          type="text"
                          {...register("delivery_text")}
                          placeholder="Enter your delivery_text "
                        />
                        {errors?.delivery_text && (
                          <p className="text-danger">
                            {errors?.delivery_text?.message}
                          </p>
                        )}
                      </div>

                      <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="delivery_text">
                         Delivery Percent
                        </label>
                        <input
                          className="form-control"
                          id="delivery_percent"
                          type="text"
                          {...register("delivery_percent")}
                          placeholder="Enter your delivery_percent "
                        />
                        {errors?.delivery_percent && (
                          <p className="text-danger">
                            {errors?.delivery_percent?.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="row">
                    <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="tax">
                          Tax
                        </label>
                        <input
                          className="form-control"
                          id="tax"
                          type="text"
                          {...register("tax")}
                          placeholder="Enter your tax"
                        />
                        {errors?.tax && (
                          <p className="text-danger">{errors?.tax?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="map_url">
                        Map URL
                        </label>
                        <input
                          className="form-control"
                          id="map_url"
                          type="text"
                          {...register("map_url")}
                          placeholder="Enter your map_url"
                        />
                        {errors?.map_url && (
                          <p className="text-danger">{errors?.map_url?.message}</p>
                        )}
                      </div>
                      
                    </div>

                    <div className="row">
                    
                    <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="min_range">
                          Min Range
                        </label>
                        <input
                          className="form-control"
                          id="min_range"
                          type="text"
                          {...register("min_range")}
                          placeholder="Enter your min_range"
                        />
                        {errors?.min_range && (
                          <p className="text-danger">{errors?.min_range?.message}</p>
                        )}
                      </div>
                      <div className="col-lg-6 mb-4">
                        <label className="form-label" htmlFor="max_range">
                        Max Range
                        </label>
                        <input
                          className="form-control"
                          id="max_range"
                          type="text"
                          {...register("max_range")}
                          placeholder="Enter your max_range"
                        />
                        {errors?.max_range && (
                          <p className="text-danger">{errors?.max_range?.message}</p>
                        )}
                      </div>
                      
                    </div>
                    <div className="row">
                    
                    <div className="col-lg-6 mb-4">
                       
                      </div>
                      <div className="col-lg-6 mb-4 d-flex justify-content-end ">
                        <input type="submit"  value="submit" className="btn btn-md rounded font-sm hover-up" />
                      </div>
                      
                    </div>
                    </div>

                    
                  
                  {/* </div> */}
                {/* </div> */}
              </Col>
            </Row>
        
     
      </Form>
    </div>
  )
}

export default Setting
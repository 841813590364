import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";

import {
  getInventryDataRequest,
  postInventryDataRequest,
  updateInventryDataRequest,
} from "../../store/InventryScreen/Action";
import {
  getProductRequest,
  getProductStockRequest,
  postProductStockRequest,
  UpdateProductStockRequest,
} from "../../store/product/product.action";
import { selectDefault } from "../../utils/Constants";
import {
  ProductStockCreateValidator,
  ProductStockValidator,
} from "../../validators/Validators";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { InventrySelector } from "../../store/InventryScreen/Selector";
import { productSelector } from "../../store/product/product.model";

const ProductStock = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(ProductStockCreateValidator),
  });
  console.log(errors, "56789sj");
  const [value, setValues] = useState(null);
  const [comments, setComments] = useState();
  // Selectors
  const { getInventData, postSuccess, updateSuccess, deleteSuccess } =
    useSelector(InventrySelector);
  const { getProductData, stock, postStockSuccess, updateStockSuccess } =
    useSelector(productSelector);
  console.log(stock, "stockxx");
  // States
  const [id, setId] = useState(0);
  const [edit, setEdit] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  /* Function */
  const totalPages = Math.ceil(getInventData?.count / rowsPerPage) || 1;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const onSubmitHandler = (formData) => {
    delete formData.product_name;
    delete formData.quantity;
    formData.comments = comments;
    formData.stock_added_date = value;
    console.log(formData, "hsj");

    if (id === 0) {
      dispatch(postProductStockRequest(formData));
    } else {
      dispatch(UpdateProductStockRequest({ formData, id }));
    }
  };

  const handleEdit = (val) => {
    let date = new Date(val?.updated_at);
    const formattedDate = date.toLocaleDateString("en-US");
    const [month, day, year] = formattedDate.split("/");
    const dates = new Date(year, month - 1, day);
    const formattedDates = dates.toISOString().split("T")[0];

    setId(val.id);
    reset({
      product_id: val?.product_id,
      total_qty: val?.total_qty,
    });

    setValues(formattedDates);
    setComments(val.comments);
  };
  console.log(value, "yhn");

  //   const handleDelete = (id) => {
  //     dispatch(deleteInventryDataRequest(id));
  //   };

  useEffect(() => {
    const payload = {
      query: 0,
      prod_id: 0,
      page: "",
      limit: "",
    };
    dispatch(getProductRequest(payload));
  }, [rowsPerPage, currentPage, searchQuery]);
  useEffect(() => {
    const payload = {
      query: 0,
      page: "",
      limit: "",
    };
    dispatch(getProductStockRequest(payload));
  }, [rowsPerPage, currentPage, searchQuery]);

  useEffect(() => {
    if (updateStockSuccess || updateStockSuccess) {
        console.log("here");
        
      const payload = {
        query: 0,
        page: "",
        limit: "",
      };
      dispatch(getProductStockRequest(payload));
      setId(0);
      reset({
        product_id: "",
        total_qty: "",
      });

      setValues("");
      setComments("");
    }
  }, [postStockSuccess, updateStockSuccess]);
  useEffect(()=>{
    if(id !==0){
        setEdit(true)
    }
    else{
        setEdit(false)
    }
  },[id])
  console.log(getInventData, "ll");
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">
                Product Stock Management
              </h2>
              <p>Add, edit or delete product Stock items</p>
            </div>
            <div>
              <input
                className="form-control bg-white"
                type="text"
                placeholder="Search Product"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <Row>
                <Col md={12}>
                  <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <div className="row">
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Product Name</label>
                        <select
                          {...register("product_id")}
                          className="form-control"
                          disabled={edit}
                        >
                          <option value={selectDefault} disabled>
                            SELECT
                          </option>
                          {getProductData?.data?.map((product) => (
                            <option key={product.id} value={product.id}>
                              {product.name}
                            </option>
                          ))}
                        </select>
                        {errors.product_id && (
                          <p className="text-danger">
                            {errors.product_id.message}
                          </p>
                        )}
                      </div>
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          {...register("total_qty")}
                        />
                        {errors.total_qty && (
                          <p className="text-danger">
                            {errors.total_qty.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Date</label>
                        <br />
                        <input
                          type="date"
                          className="form-control"
                          value={value}
                          onChange={(newValue) => {
                            console.log(newValue.target.value, "yhn");

                            return setValues(newValue.target.value);
                          }}
                        />
                      </div>
                      <div className="mb-4 col-lg-6">
                        <label className="form-label">Comments</label>
                        <textarea
                          className="form-control"
                          value={comments}
                          onChange={(e) => {
                            setComments(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>

                    <div className=" row">
                      <div className="mb-4 col-lg-12 d-flex justify-content-end">
                        <button className="btn btn-primary" type="submit">
                          {id === 0 ? "Add stock" : "Update Stock"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Col>
              </Row>
            </div>
          </div>

          <div className="card-body">
            <div className="table-responsive">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {/* <th>ID</th> */}
                    <th>Product</th>
                    {/* <th>Opening Qty</th> */}
                    <th>Quantity</th>
                    <th>Added Date</th>
                    {/* <th>Outward Qty</th> */}
                    <th>Comments</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {stock &&
                    stock?.map((item, index) => {
                      let date = new Date(item?.updated_at);
                      const formattedDate = date.toLocaleDateString("en-US");
                      return (
                        <tr key={item.id}>
                          <td>{item?.products?.name}</td>
                          <td>{item?.total_qty}</td>

                          <td>{formattedDate}</td>
                          <td>{item?.comments}</td>

                          <td>
                            <button
                              className="btn btn-light"
                              onClick={() => handleEdit(item)}
                            >
                              Edit
                            </button>
                            {/* <button
                            className="btn btn-danger" 
                          >
                            Delete
                          </button> */}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>

            <div className="pagination-area mt-15 mb-50">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-end">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      <i className="material-icons md-chevron_left"></i>
                    </a>
                  </li>

                  {[...Array(totalPages)]?.map((_, index) => (
                    <li
                      key={index + 1}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      <a className="page-link" href="#">
                        {index + 1}
                      </a>
                    </li>
                  ))}

                  <li
                    className={`page-item ${
                      currentPage === totalPages ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      href="#"
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      <i className="material-icons md-chevron_right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
};

export default ProductStock;

import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteProductRequest,
  getProductRequest,
  productStateResetRequest,
} from "../../store/product/product.action";
import { useEffect } from "react";
import { productSelector } from "../../store/product/product.model";
import { API_BASE } from "../../services/config";
import { useState } from "react";
import noImg from "../../assets/imgs/no-image.jpg";
import Sidebar from "../Sidebar";
import Header from "../Header";
import Footer from "../Footer";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import {
  getMarketPriceRequest,
  getMarketRequest,
  postMarketPriceRequest,
  postMarketRequest,
  resetMarket,
} from "../../store/market/market.action";
import { MarketSelector } from "../../store/market/market.reducer";
import { CompanySelector } from "../../store/company/company.reducer";
import { getVegRequest } from "../../store/veg/veg.action";
import { vegSelector } from "../../store/veg/veg.model";

function MarketPrice() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user_id = localStorage.getItem("id");

  /* Selectors */
  const { getProductData, productDeleteSucces } = useSelector(productSelector);
  const { allMarket, getMarketSucess, postMarketSuccess, allMarketPrice } =
    useSelector(MarketSelector);
  const { getVegData } = useSelector(vegSelector);
  console.log(getProductData?.data, "34567");
  console.log(allMarket, "34567");
  const { CompanyUser_id } = useSelector(CompanySelector);
  console.log(CompanyUser_id, "Cid");
  // const {CompanyUser_id}=useSelector(CompanySelector)

  /* State */

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    control1,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [marketC, setMarketC] = useState();
  const [market, setMarket] = useState();
  const [Mid, setMid] = useState();

  const [selMarket, setSelMarket] = useState();
  const [Price, setPrice] = useState([]);
  console.log(selMarket, "defaultta");
  const [modalShow, setModalShow] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  //DialogBox
  function MyVerticallyCenteredModal(props) {
    const onSubmit = (data) => {
      data.b2b_user_id = Number(CompanyUser_id);
      console.log("Form Data:", data);
      dispatch(postMarketRequest(data));
      setIsModalOpen(false);
      props.onHide();
    };
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Market
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div>
              <h5>Name</h5>
              <input
                type="text"
                className="form-control"
                onChange={(e) => setValue("market_name", e.target.value)}
              />
              {errors.market_name && (
                <p style={{ color: "red" }}>{errors.market_name.message}</p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  /* Function */
  const marketChange = (e) => {
    // dispatch(resetMarket())
    if (e?.id > 0) {
      const payload = {
        query: "",
        market_id: e.id,
        page: currentPage,
        limit: rowsPerPage,
        b2b_user_id: Number(CompanyUser_id),
      };
      dispatch(getMarketPriceRequest(payload));
      const payload1 = {
            query: "",
            id: CompanyUser_id,
            page: currentPage,
            limit: rowsPerPage,
          };
          dispatch(getVegRequest(payload1));

      setMid(e.id);
      console.log(e, "yyy");
      // let temp = [...Price]
      // let updated=temp?.map((value)=>{

      //   return {...value ,market_id:e.id}

      // })
      setPrice((prevPrice) =>
        prevPrice?.map((value) => ({
          ...value,
          market_id: e.id,
        }))
      );

      // console.log(updated,'yyy');
      // setPrice(updated)
      setSelMarket(e.value);
    
    }
  };
  console.log(selMarket, "yyy");
  const handlePriceChange = (value, name, index) => {
    console.log(value, name, index, "vni");
    let temp = [...Price];
    if (name === "sale_price") {
      temp[index].sale_price = value;
      setPrice(temp);
    }
    if (name === "regular_price") {
      temp[index].regular_price = value;
      setPrice(temp);
    }
    if (name === "local_market_price") {
      if(temp[index].profit_percent >0){
        let profit=temp[index].profit_percent
        temp[index].local_market_price = value;
        temp[index].sale_price = (
          Number(value) +
          (profit / 100) * value
        ).toFixed(2);
        temp[index].regular_price = (
          Number(value) +
          (profit / 100) * value * 2
        ).toFixed(2);
        setPrice(temp);
      }
      else{
        temp[index].local_market_price = value;
        setPrice(temp);
      }
      
    }

    console.log(Price, "vni");
  };
  const handleProfitChange = (value, index) => {
    let temp = [...Price];
    if (value > 0) {
      temp[index].profit_percent = value;
      temp[index].sale_price = (
        Number(temp[index].local_market_price) +
        (value / 100) * temp[index].local_market_price
      ).toFixed(2);
      temp[index].regular_price = (
        Number(temp[index].local_market_price) +
        (value / 100) * temp[index].local_market_price * 2
      ).toFixed(2);
      console.log(temp, "vni");
      console.log(Price, "before");

      setPrice(temp);
      console.log(Price, "beforeafter");
    } else {
      temp[index].sale_price = temp[index].local_market_price;
      temp[index].regular_price = temp[index].local_market_price;
      console.log(temp, "vni");
      setPrice(temp);
    }
  };
  // const handleDelete = (id) => {
  //   dispatch(deleteProductRequest(id));
  // };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };

  const saveChange = () => {
    console.log(Price, "save");
    let payload = {
      productDetailsArray: Price,
    };
    console.log(Price, "pricesof the proe");
    dispatch(postMarketPriceRequest(payload));
    // dispatch(resetMarket())
  };
  //Pagenation
  const totalPages1 = Math.ceil(getVegData?.count / rowsPerPage) || 1;
  const [page, setPage] = useState(1);
  const currentPage = page;
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  /* useEffect */
  useEffect(() => {
    const payload = {
      query: searchQuery,
      id: CompanyUser_id,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getMarketRequest(payload));
  }, [searchQuery, currentPage, rowsPerPage]);
  useEffect(() => {
    if (postMarketSuccess) {
      const payload = {
        query: searchQuery,
        id: CompanyUser_id,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getMarketRequest(payload));
      dispatch(resetMarket());
    }
  }, [postMarketSuccess]);
 

  useEffect(() => {
    console.log(allMarketPrice, "allMarketPrice");

    getVegData?.data?.map((product, index) => {
      console.log(product, "valesjfsd");
      setPrice((prev) => {
        return [
          ...prev,
          {
            product_select_id: product.id,
            productDetails_id:null,
            sale_price: "",
            regular_price: "",
            local_market_price: "",
            profit_percent: "",
            market_id: null,
          },
        ];
      });
      console.log("vni");
    });
  }, []);

  useEffect(() => {
    if (!allMarketPrice?.data) return;

    setPrice((prevPrices) => {
      const marketPriceMap = allMarketPrice?.data?.reduce(
        (map, marketPrice) => {
          map[marketPrice?.product_select_id] = marketPrice;
          return map;
        },
        {}
      );
      return getVegData?.data?.map((product) => {
        const marketPrice = marketPriceMap[product.id];
        const existingProduct = prevPrices?.find(
          (p) => p.product_select_id === product.id
        );
        return marketPrice
          ? {
              product_select_id: product.id,
              productDetails_id: marketPrice.id || null,
              sale_price: marketPrice.sale_price || "",
              regular_price: marketPrice.regular_price || "",
              local_market_price: marketPrice.local_market_price || "",
              profit_percent: marketPrice.profit_percent || "",
              market_id:
                marketPrice.market_id || existingProduct?.market_id || null,
            }
          : {
              product_select_id: product.id,
              productDetails_id: null,
              sale_price: "",
              regular_price: "",
              local_market_price: "",
              profit_percent: "",
              market_id: existingProduct?.market_id || null,
            };
      });
    });
  }, [allMarketPrice, getVegData]);
  console.log(getVegData, "gpd");
  useEffect(() => {
    let m = allMarket?.data?.map((market) => {
      return {
        value: market.market_name,
        label: market.market_name,
        id: market.id,
      };
    });
    setMarketC(m);
  }, [allMarket]);
  useEffect(() => {
    if (postMarketSuccess) {
      let m = allMarket?.data?.map((market) => {
        return {
          value: market.market_name,
          label: market.market_name,
          id: market.id,
        };
      });
      setMarketC(m);
    }
  }, [postMarketSuccess]);
  // useEffect(()=>{
  //   const payload = {
  //     query: "",
  //     market_id: Mid,
  //     page: currentPage,
  //     limit: rowsPerPage,
  //   };
  //   dispatch(getMarketPriceRequest(payload))

  // },[Mid])
  console.log(Price, "pddpp");
  console.log(allMarketPrice, "p0p0");
  // console.log(getVegData,'p0p0');

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Market & Price</h2>
            </div>
            <div>
              <Button variant="primary" onClick={() => setModalShow(true)}>
                Create New
              </Button>

              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0"></div>
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Market...."
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    isSearchable={true}
                    name="market"
                    options={marketC}
                    // styles={customStyles}
                    onChange={marketChange}
                  />
                </div>
                <div className="col-md-2 col-6">
                  {/* <select className="form-select">
                    <option selected="">Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Show all</option>
                  </select> */}
                </div>

                <div className="col-md-2 col-6">
                  <select
                    className="form-select"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value={10}>Show 10</option>
                    <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                  </select>
                </div>
              </div>
            </header>

            <div className="card-body">
              <article className="itemlist">
                <div className="row align-items-center">
                  <div className="col-lg-1 col-sm-2 col-4 col-price   ">
                    <h6 className="mb-0">Image</h6>
                  </div>

                  <div className="col-lg-1 col-sm-4 col-4 col-name flex-grow ">
                    <h6 className="mb-0 ">Name</h6>
                  </div>
                  <div className="col-lg-2 col-sm-4 col-4 col-name flex-grow-1 px-4 text-center">
                    <h6 className="mb-0 ">Market</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-status">
                    <h6 className="mb-0">Local Market Price</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-date">
                    <h6 className="mb-0">Profit Percentage</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Regular Price</h6>
                  </div>
                  <div className="col-lg-2 col-sm-2 col-4 col-price">
                    <h6 className="mb-0">Sale Price</h6>
                  </div>

                  {/* <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                    <h6 className="mb-0">Action</h6> */}
                  {/* </div> */}
                </div>
              </article>

              {selMarket != undefined &&
              getVegData?.data &&
              getVegData?.data?.length > 0 ? (
                getVegData?.data?.map((product, index) => (
                  <article className="itemlist" key={product.id}>
                    <div className="row align-items-center">
                      <div className="col-lg-1 col-sm-4 col-4  col-name">
                        <div className="left">
                          <img
                            className="img-sm img-thumbnail"
                            src={
                              product?.products?.image
                                ? API_BASE + product?.products.image
                                : noImg
                            }
                            alt="Item"
                          />
                        </div>
                      </div>
                      <div className="col-lg-1 col-sm-3 col-6 flex-grow-1 col-price flex-grow-1 ">
                        <span> {product?.products?.name}</span>
                      </div>
                      <div className="col-lg-1 col-sm-3 col-6 flex-grow-1 col-price flex-grow-1 ">
                        <span>
                          {" "}
                          {product?.market?.market_name ?? selMarket}
                        </span>
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-price ">
                        <input
                          type="text"
                          
                          value={Price&&Price[index]?.local_market_price }
                          className="form-control"
                          onChange={(e) =>
                            handlePriceChange(
                              e.target.value,
                              "local_market_price",
                              index
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-price">
                        <input
                          type="text"
                          className="form-control"
                          value={Price&&Price[index]?.profit_percent}
                          onChange={(e) =>
                            handleProfitChange(e.target.value, index)
                          }
                        />
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-price">
                        <input
                          type="text"
                          value={Price&&Price[index]?.regular_price}
                          className="form-control"
                          onChange={(e) =>
                            handlePriceChange(
                              e.target.value,
                              "regular_price",
                              index
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-2 col-sm-2 col-4 col-date">
                        <input
                          type="text"
                          value={Price&&Price[index]?.sale_price}
                          className="form-control"
                          onChange={(e) =>
                            handlePriceChange(
                              e.target.value,
                              "sale_price",
                              index
                            )
                          }
                        />
                        {/* <span>
                          {new Date(product.created_at).toLocaleDateString()}
                        </span> */}
                      </div>
                      {/* <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                        <button
                          className="btn btn-sm font-sm rounded btn-brand mr-5"
                          onClick={() =>
                            navigate(`/product/edit/${product?.id}`)
                          }
                        >
                          <i className="material-icons md-edit"></i> Edit
                        </button>
                        <button
                          className="btn btn-sm font-sm btn-light rounded"
                          onClick={() => handleDelete(product.id)}
                        >
                          <i className="material-icons md-delete_forever"></i>{" "}
                          Delete
                        </button>
                      </div> */}
                    </div>
                  </article>
                ))
              ) : (
                <p></p>
              )}
            </div>
            {console.log(Price, "ppp")}
            <div className="row">
              <div className="col-12 text-end pe-5 mb-3">
                <button className="btn btn-primary" onClick={saveChange}>
                  Save
                </button>
              </div>
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {pagesToDisplay?.map((page) => {
                  return page != "..." ? (
                    <li
                      key={page}
                      className={`page-item b ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ) : page == "..." ? (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ) : (
                    ""
                  );
                })}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default MarketPrice;

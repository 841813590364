import { decryptdata } from "../../utils/encrypt&decrypt";
import { productActionTypes, productInitialState } from "./product.model";

const reducer = (state = productInitialState, action) => {
  switch (action.type) {
    case productActionTypes.POSTPRODUCT_REQUEST:
      return {
        ...state,
        postProLoading: true,
        productSendSuccess: false,
      };
    case productActionTypes.POSTPRODUCT_SUCCESS:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: true,
      };
    case productActionTypes.POSTPRODUCT_FAILURE:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: false,
      };
    case productActionTypes.GETPRODUCT_REQUEST:
      return {
        ...state,
        getProLoading: true,
      };
    case productActionTypes.GETPRODUCT_SUCCESS:
      console.log(action?.payload, process.env.SECRET_KEY, "jkmls");
      let payloaddatas11 = JSON.parse(
        decryptdata(
          action?.payload?.data?.datas,
          "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
        )
      );
      console.log(payloaddatas11.data, "yhoio");
      return {
        ...state,
        getProLoading: false,
        getProductData: payloaddatas11,
      };
    case productActionTypes.GETPRODUCT_FAILURE:
      return {
        ...state,
        getProLoading: false,
      };
    case productActionTypes.UPDATEPRODUCT_REQUEST:
      return {
        ...state,
        updateProLoading: true,
        productUpdateSuccess: false,
      };
    case productActionTypes.UPDATEPRODUCT_SUCCESS:
      return {
        ...state,
        updateProLoading: false,
        productUpdateSuccess: true,
      };
    case productActionTypes.UPDATEPRODUCT_FAILURE:
      return {
        ...state,
        updateProLoading: false,
        productUpdateSuccess: false,
      };

    case productActionTypes.DELETEPRODUCT_REQUEST:
      return {
        ...state,
        deleteproductLoading: true,
        productDeleteSucces: false,
      };
    case productActionTypes.DELETEPRODUCT_SUCCESS:
      return {
        ...state,
        deleteproductLoading: false,
        productDeleteSucces: true,
      };
    case productActionTypes.DELETEPRODUCT_FAILURE:
      return {
        ...state,
        deleteproductLoading: false,
        productDeleteSucces: false,
      };
    case productActionTypes.OUTOFSTOCKPRODUCT_REQUEST:
      return {
        ...state,
        deleteproductLoading: true,
        productDeleteSucces: false,
      };
    case productActionTypes.OUTOFSTOCKPRODUCT_SUCCESS:
      return {
        ...state,
        deleteproductLoading: false,
        productDeleteSucces: true,
      };
    case productActionTypes.OUTOFSTOCKPRODUCT_FAILURE:
      return {
        ...state,
        outofstockLoading: false,
        outofstockSucess: false,
      };

    case productActionTypes.STATE_RESET_REQUEST:
      return {
        ...state,
        postProLoading: false,
        productSendSuccess: false,
        getProLoading: false,
        updateProLoading: false,
        productUpdateSuccess: false,
        getProductData: [],
        deleteproductLoading: false,
        productDeleteSucces: false,
        postPriceLoading: false,
        postPriceSuccess: false,
        postStockLoading: false,
        postStockSuccess: false,
        getStockLoading: false,
        stock: [],
      };
    case productActionTypes.POSTPRODUCTPRICE_REQUEST:
      return {
        ...state,
        postPriceLoading: true,
        postPriceSuccess: false,
      };
    case productActionTypes.POSTPRODUCTPRICE_SUCCESS:
      return {
        ...state,
        postPriceLoading: false,
        postPriceSuccess: true,
      };
    case productActionTypes.POSTPRODUCTPRICE_FAILURE:
      return {
        ...state,
        postPriceLoading: false,
        postPriceSuccess: false,
      };

    case productActionTypes.POSTPRODUCTSTOCK_REQUEST:
      return {
        ...state,
        postStockLoading: true,
        postStockSuccess: false,
      };
    case productActionTypes.POSTPRODUCTSTOCK_SUCCESS:
      return {
        ...state,
        postStockLoading: false,
        postStockSuccess: true,
      };
    case productActionTypes.POSTPRODUCTSTOCK_FAILURE:
      return {
        ...state,
        postStockLoading: false,
        postStockSuccess: false,
      };
    case productActionTypes.GETPRODUCTSTOCK_REQUEST:
      return {
        ...state,
        getStockLoading: true,
      };
    case productActionTypes.GETPRODUCTSTOCK_SUCCESS:
      console.log(
        action?.payload.data?.datas?.data,
        process.env.SECRET_KEY,
        "jkllj"
      );
      // let payloaddatas12 = JSON.parse(
      //   decryptdata(
      //     action?.payload?.data?.datas.data,
      //     "pubkey-b55b1e3a8d35df839ae1c9fe33ac7ad7"
      //   )
      // );
      // console.log(payloaddatas12, "jkllj");
      return {
        ...state,
        getStockLoading: false,
        stock: action?.payload.data?.datas?.data,
      };
    case productActionTypes.GETPRODUCTSTOCK_FAILURE:
      return {
        ...state,
        getStockLoading: false,
      };

    case productActionTypes.UPDATEPRODUCTSTOCK_REQUEST:
      return {
        ...state,
        updateStockLoading: true,
        updateStockSuccess: false,
      };
    case productActionTypes.UPDATEPRODUCTSTOCK_SUCCESS:
      return {
        ...state,
        updateStockLoading: false,
        updateStockSuccess: true,
      };
    case productActionTypes.UPDATEPRODUCTSTOCK_FAILURE:
      return {
        ...state,
        updateStockLoading: false,
        updateStockSuccess: false,
      };

      case productActionTypes.POSTBULKPRODUCT_REQUEST:
        return {
          ...state,
          postBulkProLoading: true,
          productBulkSendSuccess: false,
        };
      case productActionTypes.POSTBULKPRODUCT_SUCCESS:
        return {
          ...state,
          postBulkProLoading: false,
          productBulkSendSuccess: true,
        };
      case productActionTypes.POSTBULKPRODUCT_FAILURE:
        return {
          ...state,
          postBulkProLoading: false,
          productBulkSendSuccess: false,
        };
    default:
      return state;
  }
};
export { reducer as productReducer };

export const transportActionType={
    GETTRANSPORT_REQUEST:"@@transport/GETTRANSPORT_REQUEST",
    GETTRANSPORT_SUCCESS:"@@transport/GETTRANSPORT_SUCCESS",
    GETTRANSPORT_FAILURE:"@@transport/GETTRANSPORT_FAILURE",

    POSTTRANSPORT_REQUEST:"@@transport/POSTTRANSPORT_REQUEST",
    POSTTRANSPORT_SUCCESS:"@@transport/POSTTRANSPORT_SUCCESS",
    POSTTRANSPORT_FAILURE:"@@transport/POSTTRANSPORT_FAILURE",

    UPDATETRANSPORT_REQUEST:"@@transport/UPDATETRANSPORT_REQUEST",
    UPDATETRANSPORT_SUCCESS:"@@transport/UPDATETRANSPORT_SUCCESS",
    UPDATETRANSPORT_FAILURE:"@@transport/UPDATETRANSPORT_FAILURE",

    GETVEHICLE_REQUEST:"@@transport/GETVEHICLE_REQUEST",
    GETVEHICLE_SUCCESS:"@@transport/GETVEHICLE_SUCCESS",
    GETVEHICLE_FAILURE:"@@transport/GETVEHICLE_FAILURE",
}

export const transportInitialState={
    getTransportLoading:false,
    transport:[],
    postTranpostLoading:false,
    postTranpostSuccess:false,
    updateTranpostLoading:false,
    updateTranpostSuccess:false,
    getVechileLoading:false,
    vehicle:[],
}


import React, { useEffect, useState } from "react";
import DateRangePicker from "rsuite/DateRangePicker";
import "rsuite/DateRangePicker/styles/index.css";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { couponValidate } from "../../utils/Validation";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { getUserDataRequest, userSelector } from "../../store/user/user.action";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import {
  getCouponRequest,
  postCouponRequest,
  resetCoupon,
  updateCouponRequest,
} from "../../store/coupon/coupon.action";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { CouponSelector } from "../../store/coupon/coupon.reducer";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
function CouponsAdd() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [value, setValues] = useState(null);
  const [type, setType] = useState();
  const [nameData, setNameData] = useState([]);
  const [perStyle, setperStyle] = useState();
  const [flatStyle, setflatStyle] = useState();
  const [count, setCount] = useState(0);
  const [user_data, setUser_data] = useState([]);
  const { userData } = useSelector(userSelector);
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);
  const { allCoupons, postSuccess, updateSuccess } = useSelector(CouponSelector);
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);

  const [DateWarning, setDateWarning] = useState({ display: "none" });

  const handleChange = (selectedOptions) => {
    console.log(selectedOptions[count], user_data, "selectedOptions");
    if (selectedOptions[count]) {
      setUser_data((prev) => [...prev, selectedOptions[count]]);
      setCount(count + 1);
    } else {
      setUser_data(selectedOptions || []);
    }
  };
    useEffect(() => {
      if (postSuccess) {
        dispatch(resetCoupon());
        navigate("/coupons");
      }
      if (updateSuccess) {
        dispatch(resetCoupon());
        navigate("/coupons");
      }
    }, [postSuccess, updateSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(couponValidate),
  });
  console.log(errors, "errors");
  const onSubmithandler = (formData) => {
     console.log(formData,"ioio");
    if (value) {
      setDateWarning({ display: "none" });
      formData.user_data = user_data?.map((value) => {
        return value?.value;
      });
      formData.email_status = 1;
      formData.coupon_type = type === "percentage" ? 0 : 1;
      // formData.date_from = dayjs(value[0]).format("DD/MM/YYYY");
      formData.date_from = value[0];
      formData.date_to = value[1];
      // formData.date_to = dayjs(value[1]).format("DD/MM/YYYY");
      formData.email_data = emails;
      if (formData.price_flat === "") {
        formData.price_flat = null;
      }
      if (formData.percentage === "") {
        formData.percentage = null;
      }
      console.log(formData, value[0], "ioio");
      if (id) {
        formData.id = id;
        MySwal.fire({
          title: " Edit the coupon??",
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
        }).then((result)=>{
          if(result.isConfirmed){
            console.log(formData,'yfygg')
            dispatch(updateCouponRequest(formData));
          }
        })
        
      } else {
        dispatch(postCouponRequest(formData));
      }
    } else {
      setDateWarning({});
    }
  };
  // useEffect(() => {
  //   console.log(user_data, nameData, "user");
  // }, [user_data]);
  useEffect(()=>{

  })
  useEffect(() => {
    const payload = {
      query: "",
      page: 1,
      limit: 10,
      userId: "",
    };
    dispatch(getUserDataRequest(payload));
  }, []);
  useEffect(() => {
    if (userData) {
      userData?.map((data) => {
        return (
          console.log(data?.name, "jgvsjfv"),
          setNameData((prev) => [
            ...prev,
            { value: data.name, label: data?.name },
          ])
        );
      });
    }
  }, [userData]);
  useEffect(() => {
    if (type === "percentage") {
      setperStyle();
      setflatStyle("disable");
    }
    if (type === "flat_price") {
      setperStyle("disable");
      setflatStyle();
    }
  }, [type]);
  useEffect(() => {
    console.log(user_data, nameData, "user");
  }, [user_data]);
  // console.log(nameData, "namess");
  useEffect(() => {
    if (id) {
      dispatch(getCouponRequest());
    }
  }, [id]);

  // console.log(id,'zxc');
  useEffect(() => {
    if (id) {
      console.log(id, "zxc");
      let sel = allCoupons.find((c) => c.id == id);
      console.log(sel, "zxc");
      reset({
        coupon_name: sel.coupon_name,
        coupon_code: sel.coupon_code,
        percentage: sel.percentage,
        price_flat: sel.price_flat,
      });
      sel?.percentage === null ? setType("flat_price") : setType("percentage");
      setEmails(sel.email_data);
      // let useer_name=sel.user_data.map((n)=>{
        
      // })
      // setUser_data(sel.user_data);
    }
  }, [id]);

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <section className="content-main">
          <form onSubmit={handleSubmit(onSubmithandler)}>
            <div className="content-header">
              <h2 className="content-title">
                {id?"Edit Coupon":"Add Coupon"}
                </h2>
            </div>
            <div className="content-body">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="coupon_name">
                        Coupon Name
                      </label>
                      <input
                        className="form-control"
                        id="coupon_name"
                        type="text"
                        {...register("coupon_name")}
                        placeholder="Enter  coupon_name"
                      />
                      {errors?.coupon_name && (
                        <p className="text-danger">
                          {errors?.coupon_name?.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="coupon_code">
                        Coupon Code
                      </label>
                      <input
                        className="form-control"
                        id="coupon_code"
                        type="text"
                        {...register("coupon_code")}
                        placeholder="Enter  coupon_code"
                      />
                      {errors?.coupon_code && (
                        <p className="text-danger">
                          {errors?.coupon_code?.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="coupon_type">
                        Coupon Type
                      </label>
                      <div className="d-flex flex-column justify-content-center">
                        <div className="">
                          <input
                            type="radio"
                            name="coupon_type"
                            id="percentage"
                            value="percentage"
                            checked={type === "percentage"}
                            onClick={() => {
                              setValue("price_flat",null)
                              setType("percentage");
                            }}
                          />
                          <label htmlFor="percentage">Percentage</label>
                        </div>
                        <div className="">
                          <input
                            type="radio"
                            name="coupon_type"
                            id="flat_price"
                            value="flat_price"
                            checked={type === "flat_price"}
                            onClick={() => {
                              setValue("percentage",null)
                              setType("flat_price");
                            }}
                          />
                          <label htmlFor="flat_price">Flat Price</label>{" "}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="tax">
                        Date Range
                      </label>
                      <br />
                      <DateRangePicker
                        showOneCalendar
                        // shouldDisableDate={afterToday()}
                        value={value}
                        onChange={(newValue) => setValues(newValue)}
                        placeholder="Select Date Range"
                      />
                      <p className="text-danger" style={DateWarning}>
                        Date is Required
                      </p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="percentage">
                        Percentage
                      </label>
                      <input
                        className="form-control"
                        id="percentage"
                        type="text"
                        {...register("percentage")}
                        placeholder="Enter  percentage "
                        disabled={perStyle}
                      />

                      {errors?.percentage && (
                        <p className="text-danger">
                          {errors?.percentage?.message}
                        </p>
                      )}
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="price_flat">
                        Flat Price
                      </label>
                      <input
                        className="form-control"
                        id="price_flat"
                        type="text"
                        {...register("price_flat")}
                        placeholder="Enter price_flat"
                        disabled={flatStyle}
                      />
                      {errors?.price_flat && (
                        <p className="text-danger">
                          {errors?.price_flat?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="email_data">
                        Email Data
                      </label>

                      <ReactMultiEmail
                        placeholder="Input your email"
                        emails={emails}
                        onChange={(_emails) => {
                          setEmails(_emails);
                        }}
                        autoFocus={true}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        getLabel={(email, index, removeEmail) => {
                          return (
                            <div data-tag key={index}>
                              <div data-tag-item>{email}</div>
                              <span
                                data-tag-handle
                                onClick={() => removeEmail(index)}
                              >
                                ×
                              </span>
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="col-lg-6 mb-4">
                      <label className="form-label" htmlFor="user_data">
                        User Data
                      </label>
                      <Select
                        isMulti
                        name="colors"
                        options={nameData}
                        value={user_data}
                        onChange={handleChange}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6 mb-4"></div>
                      <div className="col-lg-6 mb-4 d-flex justify-content-end ">
                        <input
                          type="submit"
                          value="submit"
                          className="btn btn-md rounded font-sm hover-up"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default CouponsAdd;

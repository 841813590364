import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";

import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";

import { useState } from "react";

import { CompanySelector } from "../../store/company/company.reducer";
import {
  deleteCompanyRequest,
  getCompanyRequest,
  resetCompany,
} from "../../store/company/company.action";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { createSchema, veges } from "../../utils/Validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  getProductRequest,
  postProductRequest,
} from "../../store/product/product.action";
import { productSelector } from "../../store/product/product.model";
import { API_BASE } from "../../services/config";
import { deleteVegRequest, getVegRequest, postVegRequest } from "../../store/veg/veg.action";
import { vegSelector } from "../../store/veg/veg.model";

function VegetableList() {
  let user_id = localStorage.getItem("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  /* Selectors */
  const { allCompany } = useSelector(CompanySelector);
  const {CompanyUser_id}=useSelector(CompanySelector)

  /* State */
  const MySwal = withReactContent(Swal);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);


  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  
  const { getProductData } = useSelector(productSelector);
  const { getVegData ,deleteVegSuccess,VegSendSuccess} = useSelector(vegSelector);
  const [opt, setOpt] = useState();
  const [optd, setOptd] = useState();

  /* Function */

  function MyVerticallyCenteredModal(props) {
    const onSubmit = (data) => {
      let product_id = [];
      data?.vegetable?.map((d) => {
        return product_id.push(d.id);
      });
      const payload = { b2b_user_id: CompanyUser_id, product_ids: product_id };
      dispatch(postVegRequest(payload));
      console.log("Form Data:", data, payload);
      setIsModalOpen(false); // Close the modal after submission
      props.onHide();
    };
    console.log(optd,"jj");
    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Vegetables
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div>
              name
              <Controller
                name="vegetable"
                control={control}
                rules={{ required: "Please select at least one vegetable" }}
                render={({ field }) => (
                  <Select
                    {...field}
                    defaultValue={optd}
                    options={opt}
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(val) => setValue("vegetable", val)} // Update the form state
                  />
                )}
              />
              {errors.vegetable && (
                <p style={{ color: "red" }}>{errors.vegetable.message}</p>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
          <Button onClick={handleSubmit(onSubmit)}>Save</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const totalPages1 = Math.ceil(allCompany?.count / rowsPerPage) || 1;

  const handleDelete = (id) => {
    console.log(id,'qaz');
    dispatch(deleteVegRequest(id));
  };
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
  };
  const currentPage = page; 
  const prevPage = currentPage > 1 ? currentPage - 1 : null;
  const nextPage1 = currentPage < totalPages1 ? currentPage + 1 : null;
  const nextPage2 =
    totalPages1 != currentPage
      ? currentPage < totalPages1
        ? currentPage + 2
        : null
      : null;
  const lastPage_before = totalPages1 != currentPage ? "..." : null;
  const lastPage = totalPages1 != currentPage ? totalPages1 : null;
  const pagesToDisplay = [
    prevPage,
    currentPage,
    nextPage1,
    nextPage2,
    lastPage_before,
    lastPage,
  ].filter((page) => page !== null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  /* useEffect */

  useEffect(() => {
    const payload = {
      query: searchQuery,
      id: CompanyUser_id,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getVegRequest(payload));
    const payload1 = {
      query: searchQuery,
      prod_id: 0,
      page: currentPage,
      limit: rowsPerPage,
    };
    dispatch(getProductRequest(payload1));
    if(deleteVegSuccess || VegSendSuccess){
      const payload = {
        query: searchQuery,
        id: CompanyUser_id,
        page: currentPage,
        limit: rowsPerPage,
      };
      dispatch(getVegRequest(payload));
    }
  }, [searchQuery, currentPage, rowsPerPage,deleteVegSuccess,VegSendSuccess]);


  useEffect(() => {
    let o = getProductData?.data?.map((product) => {
      return { value: product.name, label: product.name, id: product.id };
    });
    console.log(o, "uiui");
    setOpt(o);
  }, []);

  useEffect(() => {

    let opt_id = [];
    opt?.map((o) => {
      opt_id.push(o.id);
    });

    let filtered = getVegData?.data?.filter((pro) =>
      opt_id.includes(pro.product_id)
    );
    // console.log(filtered[0]?.product_id, "vegss id");
    let filtered_id=[]
    filtered?.map((veg)=>{
      filtered_id.push(veg.product_id)
    })
    console.log(filtered_id,"vvv");

    
    let fin = opt?.filter((o) => {
      return filtered_id?.includes(o.id);
    });
    console.log(fin,'vvvi');
    
    setOptd(fin)
    if(deleteVegSuccess){
      console.log(("deleted"));
      
      let opt_id = [];
      opt?.map((o) => {
        opt_id.push(o.id);
      });
      console.log(getVegData.data,"vvvvv");
      let filtered = getVegData?.data?.filter((pro) =>
        opt_id.includes(pro.product_id)
      );
      console.log(filtered[0]?.product_id, "vegss id");
      let filtered_id=[]
      filtered?.map((veg)=>{
        filtered_id.push(veg.product_id)
      })
      console.log(filtered_id,"vvv");
  
      
      let fin = opt?.filter((o) => {
        return filtered_id?.includes(o.id);
      });
      console.log(fin,'vvvi');
      
      setOptd(fin)
    }
    
    
  }, [opt,deleteVegSuccess]);
// useEffect(()=>{
//   if(deleteVegSuccess){
//     let deleted=optd.map
    
//     setOptd()
//   }
  
// },[deleteVegSuccess])
  console.log(getVegData.data,"rtrt");

  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Vegetable List</h2>
            </div>
            <div>
              {/* <button
                className="btn btn-primary btn-sm rounded"
                onClick={(e) => {
                  e.preventDefault();
                  MySwal.fire({
                    title: " Add New Vegetable",
                    html:`
                     <form submit={handlesubmit()} >
                     <div className="mb-3"> 
                     <label class='text-end'>
                     Vegetable
                     </label>
                    <input type="text" class="form-control" {...register("name)}/></div>
                     <div> Category
                    <input type="text" class="form-control"{...register("category)}/></div>
                     <div>image
                    <input type="file" class="form-control"/</div>
                   
                   
                    >
                    </div
                </form>
                    `,
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                  }).then((result) => {
                    if (result.isConfirmed) {
                    //   dispatch(updateCompanyRequest(formData));
                    }
                  });


                //   dispatch(resetCompany());
                //   navigate("/company/add");
                }}
              >
                {" "}
                Create new
              </button> */}
              <Button variant="primary" onClick={() => setModalShow(true)}>
                Create New
              </Button>

              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </div>
          </div>
          <div className="card mb-4">
            <header className="card-header">
              <div className="row align-items-center">
                <div className="col col-check flex-grow-0">
                  {/* <div className="form-check ms-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                    />
                  </div> */}
                </div>
                <div className="col-md-3 col-12 me-auto mb-md-0 mb-3">
                  {/* <select className="form-select">
                    <option selected="">All category</option>
                    <option>Switches</option>
                    <option>Adapter</option>
                    <option>Panels</option>
                    <option>Wires</option>
                  </select> */}
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
                <div className="col-md-2 col-6">
                  {/* <select className="form-select">
                    <option selected="">Status</option>
                    <option>Active</option>
                    <option>Disabled</option>
                    <option>Show all</option>
                  </select> */}
                </div>
                {/* <div className="col-md-2 col-6">
                  <input
                    className="form-control"
                    type="date"
                    value="02.05.2022"
                  />
                </div> */}
                <div className="col-md-2 col-6">
                  <select
                    className="form-select"
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option value={10}>Show 10</option>
                    <option value={20}>Show 20</option>
                    <option value={30}>Show 30</option>
                  </select>
                </div>
              </div>
            </header>

            <div className="card-body">
              <article className="itemlist">
                <div className="row align-items-center">
                  <div className="col-lg-3 col-sm-2 col-4 col-price  text-center  ">
                    <h6 className="mb-0">Image</h6>
                  </div>

                  <div className="col-lg-3 col-sm-4 col-4 col-name flex-grow-1 px-4  text-center">
                    <h6 className="mb-0 ">Name</h6>
                  </div>
                  <div className="col-lg-3 col-sm-2 col-4 col-status text-center">
                    <h6 className="mb-0">Categeory</h6>
                  </div>
                  <div className="col-lg-3 col-sm-2 col-4 col-price text-center">
                    <h6 className="mb-0">Action</h6>
                  </div>
                  {/* <div className="col-lg-2 col-sm-2 col-4 col-price text-center">
                    <h6 className="mb-0">City</h6>
                  </div>

                  <div className="col-lg-2 col-sm-2 col-4 col-action text-end">
                    <h6 className="mb-0">Action</h6>
                  </div> */}
                </div>
              </article>
              {getVegData && getVegData?.data ? (
                getVegData?.data.map((product) => {
                  console.log(product.products.id, "vegss");
                  console.log(product, "vegss");

                  return (
                    <article className="itemlist" key={product?.products?.id}>
                      <div className="row align-items-center">
                        <div className="col-lg-3 col-sm-2 col-4 col-price">
                          <a
                            className="itemside d-flex  justify-content-center"
                            href="#"
                          >
                            <div className="info">
                              <img
                                className="img-sm img-thumbnail"
                                src={
                                  product?.products?.image
                                    ? API_BASE + product.products.image
                                    : "noImg"
                                }
                                alt="Item"
                              />
                            </div>
                          </a>
                        </div>
                        <div className="col-lg-3 col-sm-2 col-4 col-price  ">
                          <a
                            className="itemside d-flex  justify-content-center"
                            href="#"
                          >
                            <div className="info">
                              <h6 className="mb-0">{product.products.name}</h6>
                            </div>
                          </a>
                        </div>
                        <div className="col-lg-3 col-sm-2 col-4 text-center">
                          <a
                            className="itemside d-flex  justify-content-center"
                            href="#"
                          >
                            <div className="info">
                              <h6 className="mb-0">
                                {product.products.category.name}
                              </h6>
                            </div>
                          </a>
                        </div>{" "}
                        <div className="col-lg-3 col-sm-2 col-4 col-action text-center">
                          <button
                            className="btn btn-sm font-sm btn-light rounded"
                            onClick={() => handleDelete(product.id)}
                          >
                            <i className="material-icons md-delete_forever"></i>{" "}
                            Delete
                          </button>
                        </div>
                      </div>
                    </article>
                  );
                })
              ) : (
                <p>No Product</p>
              )}
              {/* {getProductData && getProductData?.count > 0 ? (
                getProductData?.data?.map((product, index) => (
                  <article className="itemlist" key={product?.id}>
                    <div className="row align-items-center">
                      
                      <div className="col-lg-3 col-sm-2 col-4 col-price">
                        <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                          <img
                            className="img-sm img-thumbnail"
                            src={
                              product?.image ? API_BASE + product.image : "noImg"
                            }
                            alt="Item"
                          />
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-3 col-sm-2 col-4 col-price  ">
                        <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                            <h6 className="mb-0">{product.name}</h6>
                          </div>
                        </a>
                      </div>
                      <div className="col-lg-3 col-sm-2 col-4 text-center">
                      <a
                          className="itemside d-flex  justify-content-center"
                          href="#"
                        >
                          <div className="info">
                          <h6 className="mb-0">{product.category.name}</h6>
                          </div>
                        </a>
                        
                      </div>{" "}
                     
                      <div className="col-lg-3 col-sm-2 col-4 col-action text-end">
                      
                        <button
                          className="btn btn-sm font-sm btn-light rounded"
                          onClick={() => handleDelete(product.id)}
                        >
                          <i className="material-icons md-delete_forever"></i>{" "}
                          Delete
                        </button>
                      </div>
                    </div>
                  </article>
                ))
              ) : (
                <p>No product found</p>
              )} */}
            </div>
          </div>
          <div className="pagination-area mt-30 mb-50 d-flex justify-content-end">
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-end">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    <i className="material-icons md-chevron_left"></i>
                  </a>
                </li>

                {pagesToDisplay.map((page) => {
                  return page != "..." ? (
                    <li
                      key={page}
                      className={`page-item b ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        href="#"
                        onClick={() => handlePageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ) : page == "..." ? (
                    <li
                      key={page}
                      className={`page-item ${
                        currentPage === page ? "active" : ""
                      }`}
                    >
                      <a className="page-link" href="#">
                        {page}
                      </a>
                    </li>
                  ) : (
                    ""
                  );
                })}

                <li
                  className={`page-item ${
                    currentPage === totalPages1 ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    <i className="material-icons md-chevron_right"></i>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </section>

        <Footer />
      </main>
    </>
  );
}
export default VegetableList;

import { action } from "typesafe-actions";
import { companyAddActionType } from "./company.model";


export const postCompanyRequest=(req) =>
    action(companyAddActionType.POSTCOMPANY_REQUEST,req)
export const postCompanySuccess=(res)=>
    action(companyAddActionType.POSTCOMPANY_SUCCESS,res)
export const postCompanyFailure=(err)=>
    action(companyAddActionType.POSTCOMPANY_FAILURE,err)


export const getCompanyRequest=(req) =>
    action(companyAddActionType.GETCOMPANY_REQUEST,req)
export const getCompanySuccess=(res)=>
    action(companyAddActionType.GETCOMPANY_SUCCESS,res)
export const getCompanyFailure=(err)=>
    action(companyAddActionType.GETCOMPANY_FAILURE,err)


export const updateCompanyRequest=(req) =>
    action(companyAddActionType.UPDATECOMPANY_REQUEST,req)
export const updateCompanySuccess=(res)=>
    action(companyAddActionType.UPDATECOMPANY_SUCCESS,res)
export const updateCompanyFailure=(err)=>
    action(companyAddActionType.UPDATECOMPANY_FAILURE,err)

export const deleteCompanyRequest=(req) =>
    action(companyAddActionType.DELETECOMPANY_REQUEST,req)
export const deleteCompanySuccess=(res)=>
    action(companyAddActionType.DELETECOMPANY_SUCCESS,res)
export const deleteCompanyFailure=(err)=>
    action(companyAddActionType.DELETECOMPANY_FAILURE,err)

export const getCompanyUserRequest=(req) =>
    action(companyAddActionType.GETCOMPANYUSER_REQUEST,req)
export const getCompanyUserSuccess=(res)=>
    action(companyAddActionType.GETCOMPANYUSER_SUCCESS,res)
export const getCompanyUserFailure=(err)=>
    action(companyAddActionType.GETCOMPANYUSER_FAILURE,err)

export const resetCompany=(req)=>
    action(companyAddActionType.RESETCOMPANY, req)
export const settingActionType = {
  POSTBANNERDETAIL_REQUEST: "@@setting/POSTBANNERDETAIL_REQUEST",
  POSTBANNERDETAIL_SUCCESS: "@@setting/POSTBANNERDETAIL_SUCCESS",
  POSTBANNERDETAIL_FAILURE: "@@setting/POSTBANNERDETAIL_FAILURE",

  GETBANNERDATA_REQUEST: "@@setting/GETBANNERDATA_REQUEST",
  GETBANNERDATA_SUCCESS: "@@setting/GETBANNERDATA_SUCCESS",
  GETBANNERDATA_FAILURE: "@@setting/GETBANNERDATA_FAILURE",

  POSTSETTINGDATA_REQUEST: "@@setting/POSTSETTINGDATA_REQUEST",
  POSTSETTINGDATA_SUCCESS: "@@setting/POSTSETTINGDATA_SUCCESS",
  POSTSETTINGDATA_FAILURE: "@@setting/POSTSETTINGDATA_FAILURE",

  GETSETTINGDATA_REQUEST: "@@setting/GETSETTINGDATA_REQUEST",
  GETSETTINGDATA_SUCCESS: "@@setting/GETSETTINGDATA_SUCCESS",
  GETSETTINGDATA_FAILURE: "@@setting/GETSETTINGDATA_FAILURE",

};

export const settingInitialState = {
  loading: false,
  postLoading:false,
  postMessage:false,
  bannerLoad:false,
  bannerData:[],
  bannerDataSucess:false,
  getLoading:false,
  getSettingData:{},
  sendsettingSuccess:false,
  getdataSuccess:false
};

import { call,put,takeEvery } from "redux-saga/effects";
import * as transportAction from './transport.action';
import Swal from "sweetalert2";
import { transportActionType } from "./transport.model";
import { getTransportApi, getVehicleApi, postTranportApi, updateTransportApi } from "./transport.api";
import { logDOM } from "@testing-library/react";


function* handleGetTransport(action){
    try{
        const response =yield call(getTransportApi,action.payload);
        console.log(response,"lklk");
        
        yield put(transportAction.getTransportSuccess(response));
       
    } catch(e){
        yield put(transportAction.getTransportFailure(e));
     
    }
}
function* handleGetVehicle(action){
    try{
        const response =yield call(getVehicleApi,action.payload);
        console.log(response,"poiuyt");
        
        yield put(transportAction.getVehicelSuccess(response));
       
    } catch(e){
        yield put(transportAction.getVehicelFailure(e));
     
    }
}
function* handlePostTransport(action){
    try{
        const response =yield call(postTranportApi,action.payload);
        yield put(transportAction.postTransportSuccess(response));
        Swal.fire({
            title: "Success",
            text: "Transport Added Succesfully!",
            icon: "success",
          });
    } catch(e){
        yield put(transportAction.postTransportFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
}

function* handleUpdateTransport(action){
    try{
        const response =yield call(updateTransportApi,action.payload);
        yield put(transportAction.updateTransportSuccess(response));
        Swal.fire({
            title: "Success",
            text: "Transport Updated Succesfully!",
            icon: "success",
          });
    } catch(e){
        yield put(transportAction.updateTransportFailure(e));
      Swal.fire({
        title: "Error",
        text: e?.response?.data?.message,
        icon: "error",
      });
    }
}

export function* TransportSaga(){
    yield takeEvery(
        transportActionType.GETTRANSPORT_REQUEST,
        handleGetTransport
    )
    yield takeEvery(
        transportActionType.POSTTRANSPORT_REQUEST,
        handlePostTransport
    )
    yield takeEvery(
        transportActionType.UPDATETRANSPORT_REQUEST,
        handleUpdateTransport
    )
    yield takeEvery(
        transportActionType.GETVEHICLE_REQUEST,
        handleGetVehicle
    )
}
import { decryptdata } from "../../utils/encrypt&decrypt";
import { couponAddActionType, couponInitalState } from "./coupon.model";

const reducer = (state = couponInitalState, action) => {
  switch (action.type) {
    case couponAddActionType.POSTCOUPON_REQUEST:
      return {
        ...state,
        postLoading: true,
        postSuccess: false,
      };
    case couponAddActionType.POSTCOUPON_SUCCESS:
      return {
        ...state,
        postLoading: false,
        postSuccess: true,
      };
    case couponAddActionType.POSTCOUPON_FAILURE:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
      };
    case couponAddActionType.GETCOUPON_REQUEST:
      return {
        ...state,
        getLoading: true,
      };
    case couponAddActionType.GETCOUPON_SUCCESS:
      const { payload } = action;
      console.log(action.payload, process.env.REACT_APP_SECRETKEY, "jkmls");
      let payloaddatas = JSON.parse(
        decryptdata(
          action.payload?.data?.datas,
          process.env.REACT_APP_SECRETKEY
        )
      );
      console.log(payloaddatas, "yhoio");
      return {
        ...state,
        getLoading: false,
        allCoupons: payloaddatas,
      };
    case couponAddActionType.GETCOUPON_FAILURE:
      return {
        ...state,
        getLoading: false,
      };
    case couponAddActionType.UPDATECOUPON_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateSuccess: false,
      };
    case couponAddActionType.UPDATECOUPON_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: true,
      };
    case couponAddActionType.UPDATECOUPON_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: false,
      };

    case couponAddActionType.DELETECOUPON_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: false,
      };
    case couponAddActionType.DELETECOUPON_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: true,
      };
    case couponAddActionType.DELETECOUPON_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: false,
      };

    case couponAddActionType.RESETCOUPON:
      return {
        ...state,
        postLoading: false,
        postSuccess: false,
        getLoading: false,
        allCoupons: [],
        updateLoading: false,
        updateSuccess: false,
        deleteLoading: false,
        deleteSuccess: false,
      };
    default:
      return state;
  }
};
export const CouponSelector = (state) => state.couponReducer;
export { reducer as couponReducer };
